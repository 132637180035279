import { className, scrollToSection } from "../../../Utils.js";
import Slider from '../../Slider/Slider';
import { useTranslations } from "../../../lang/TranslationHooks";
import { useSelector } from "react-redux";
import { sliderData } from "../PregnancyConstants.js";
import { isMobile } from 'react-device-detect';

import cloudsWwhite from '../assets/clouds-white.png'
import './FutureMother.css'

const FutureMother = () => {
    const { language } = useSelector((state) => state.home)
    let { staticTranslate } = useTranslations();

    const ios = 'https://apps.apple.com/us/app/feia-%D0%B1%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82/id1299624022?ls=1'
    const android = "https://play.google.com/store/apps/details?id=bg.feia.Pregnancy"

    return (
        <div className={className('container-section')} id='section02'>
            <div className={className('container-section-wrapper')}>
                <div className={className('container-left')}>
                    <Slider sliderData={sliderData[language]} />
                </div>
                <div className={className('container-right')}>
                    <h2 className={className('container-right-title')}>
                        {staticTranslate('t_pregnancy_second_section_title', true)}
                    </h2>
                    <ul className={className('container-right-content')}>
                        <li
                            dangerouslySetInnerHTML={{
                                __html: staticTranslate('t_pregnancy_second_section_content')
                            }}
                        />
                    </ul>
                    <div
                        className={className('install-now-button')}
                        onClick={() => isMobile
                            ? window.location.href = window.navigator.userAgent.indexOf('Mac OS') !== -1
                                ? ios
                                : android
                            : scrollToSection('section05')}
                    >
                        {staticTranslate('t_pregnancy_second_section_install_now', true)}
                    </div>
                </div>
            </div>
            <div>
                <img
                    src={cloudsWwhite}
                    className={className('future-mother-clouds-white')}
                    alt="cloudsWwhite"
                />
            </div>
        </div>
    )
}

export default FutureMother