import { useState, useEffect } from 'react'

import { className } from '../../Utils';

import './ImageSection.css'

const ImageSection = ({ link, id, title }) => {
    const [imageUrl, setImageUrl] = useState(null)

    const fetchImageData = async id => {
        const response = await fetch(`https://feia.bg/shop/blog-site/wp-json/wp/v2/media?parent=${id}`);
        const data = await response.json();
        data.map((item) => setImageUrl(item.source_url))
    }

    useEffect(() => {
        fetchImageData(id)
    }, [id]);

    return (
        <div className={className('blog-image-container')}>
            <a href={link} target="_blank" rel="noreferrer" title={title?.rendered}>
                <img src={imageUrl}
                    alt={title.rendered} className={className('blog-item-image')} loading='lazy' />
            </a>
        </div>
    )
}

export default ImageSection
