import { isMobile } from "react-device-detect";

import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslations } from "../../../lang/TranslationHooks";
import cloudsFooter from '../../Home/assets/webp/cloudsFooter.webp'
import { className } from "../../../Utils";

import Sponsors from "../../Home/sponsors/Sponsors";
// import FooterIconContainer from "./FooterIconContainer/FooterIconContainer";

import "./Footer.css";

const Footer = () => {
  const location = useLocation();
  let { staticTranslate } = useTranslations();
  const { language } = useSelector((state) => state.home)

  const footerData = [
    {
      title: staticTranslate('t_home_feia', true),
      id: 1,
      content: [
        {
          id: 1,
          title: staticTranslate('t_footer_left_column_content_title_one', true),
          linkUrl: '/aboutUs',
          ourApps: true,
        },
        {
          id: 2,
          title: language === 'bg' ? staticTranslate('t_footer_left_column_content_title_second', true) : staticTranslate('t_footer_left_column_content_title_fifth', true),
          linkUrl: language === 'bg' ? 'https://feia.bg/community/' : '/contacts',
          ourApps: language === 'bg' ? false : true,
        },
        language === 'bg' && {
          id: 3,
          title: staticTranslate('t_feia_blog', true),
          linkUrl: 'https://feia.bg/shop/blog-site/',
          ourApps: false,
        },
        language === 'bg' && {
          id: 4,
          title: staticTranslate('t_footer_left_column_content_title_fourth', true),
          linkUrl: 'https://feia.bg/shop/',
          ourApps: false,
        }

      ].filter(Boolean),
    },
    {
      title: staticTranslate('t_pregnancy_title', true),
      id: 2,
      content: [
        {
          id: 1,
          title: staticTranslate('t_pregnancy_title', true),
          linkUrl: language === 'bg' ? '/pregnancy' : '/',
          ourApps: true,
        },
        language === 'bg' &&
        {
          id: 4,
          title: staticTranslate('t_footer_center_column_content_title_fourth', true),
          linkUrl: '/ios-payment',
          ourApps: true,
        },
        language === 'bg' && {
          id: 5,
          title: staticTranslate('t_footer_center_column_content_title_fifth', true),
          linkUrl: '/android-payment',
          ourApps: true,
        },
        language !== 'bg' &&
        {
          id: 6,
          title: staticTranslate('t_footer_right_column_content_title_third', true),
          linkUrl: '/terms',
          ourApps: true,
        },
      ].filter(Boolean),
    },
    {
      title: staticTranslate('t_footer_right_column_title', true),
      id: 3,
      content: [
        language === 'bg' && {
          id: 1,
          title: staticTranslate('t_footer_left_column_content_title_fifth', true),
          linkUrl: '/contacts',
          ourApps: true,
        },
        {
          id: 2,
          title: staticTranslate('t_footer_right_column_content_title_one', true),
          linkUrl: '/faq',
          ourApps: true,
        },
        {
          id: 3,
          title: staticTranslate('t_footer_right_column_content_title_second', true),
          linkUrl: '/privacy-policy',
          ourApps: true,
        },
        language === 'bg' &&
        {
          id: 4,
          title: staticTranslate('t_footer_right_column_content_title_third', true),
          linkUrl: '/terms',
          ourApps: true,
        },
      ].filter(Boolean),
    },
  ];


  return (
    <div className={className('footer-component') + ' ' + className(`${location.pathname === '/' && 'footer-component-home'}`)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {

          location.pathname === '/' && <Sponsors />
        }
        <div
          className={className('footer-clauds-wrapper')}
        >
          <img src={cloudsFooter} alt="clouds"
            className={className('footer-clouds')}
            loading="lazy"
          />
        </div>

      </div>
      <div className={className('footer-container')}>
        {/* <FooterIconContainer /> */}

        <div className={className('footer-content')}>
          {footerData.map((item, index) => (
            <div key={item.id + index}
              className={className('single-footer-container')}
            >
              <p className={className('footer-title') + ' ' + className(`footer-title-${item.id}`)}>
                {item.title}
              </p>
              {item.content.map((i, index) => (
                <p
                  style={{ pointerEvents: i.linkUrl === location.pathname ? 'none' : 'auto' }}
                  key={'footerLinks' + index}
                  className={className('footer-links') + '  ' + className(i.linkUrl === location.pathname && 'active-footer-link')}>
                  {i.ourApps ? <Link
                    to={i.linkUrl}
                    target='_self'
                    rel="noreferrer"
                    title={i.title}
                  >
                    {i.title}
                  </Link>
                    : <a href={i.linkUrl} target="_blank" rel="noreferrer" title={i.title}>
                      {i.title}
                    </a>
                  }
                </p>
              ))}

            </div>
          ))}

        </div>

      </div>
      <div className={className('credits')}>
        {isMobile
          ? <>
            <p> © 2017 - {new Date().getFullYear()} {staticTranslate('t_footer_all_right_reserved', true)} </p>
          </>
          : <p> © 2017 - {new Date().getFullYear()} {staticTranslate('t_footer_all_right_reserved', true)} </p>
        }
      </div>
    </div >
  );
}

export default Footer
