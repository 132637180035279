import React from 'react'
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

import Menu from "../common/Menu/Menu";
import IOSPaymentTitle from './IOSPaymentTitle/IOSPaymentTitle';
import IOSPaymentContent from './IOSPaymentContent/IOSPaymentContent';

import LastBlogArticles from '../LastBlogArticles/LastBlogArticles'
import Footer from '../common/footer/Footer';
import GlobalLoader from "../GlobalLoader/GlobalLoader";

import { className } from '../../Utils';

import './IOSPayment.css'

const IOSPayment = () => {
    const { translations, language } = useSelector(state => state.home)

    return (
        <>
            <GlobalLoader showSpinner={translations[language] ? false : true} />
            <div className="App">
                <div id="main" className={className('ios-payment-main')}>
                    <Menu mobile={isMobile} pageName='ios-payment' />
                    <div id={"scrollContainer"} className="ios-payment-main-container">
                        <IOSPaymentTitle />
                        <IOSPaymentContent />
                        {language === 'bg' &&
                            < LastBlogArticles sections="section03" />
                        }
                        <Footer />
                    </div>
                </div>
            </div>
        </>

    )

}

export default IOSPayment