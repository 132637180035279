import React from 'react'
import Carousel from 'nuka-carousel';

import { className } from '../../Utils'
import { isMobile } from "react-device-detect"

import stars from '../../assets/stars.svg'
import './Rating.css'

const Rating = ({ ratingData, home }) => {

    return (
        <div className={className('rating-container')} >
            <Carousel
                autoplay={true}
                wrapAround={true}
                dragging={true}
                speed={200}
            >
                {ratingData.map((item, index) => {
                    return <div
                        key={index}
                    >
                        <div className={className('stars')}>
                            <img
                                src={stars}
                                alt="stars"
                                style={{ width: '100%', height: '100%' }}
                                loading='lazy'
                            />
                        </div>
                        <div
                            className={className('rating-text')}
                            dangerouslySetInnerHTML={{
                                __html: isMobile
                                    ? item.textMobile
                                    : item.text
                            }} />
                        <p className={className('rating-author')}>
                            {item.author}
                        </p>

                    </div>
                })}
            </Carousel>
        </div>
    )
}

export default Rating
