import React from 'react'
import { useTranslations } from "../../../lang/TranslationHooks";

import { className } from '../../../Utils'
import { clouds, artwork } from '../../../Images'

import './PrivacyPolicyContent.css'

const PrivacyPolicyContent = () => {
    let { staticTranslate } = useTranslations();

    return (
        <div className={className('privacy-policy-wrapper')} id="section00">
            <div className={className('privacy-policy-artwork')}>
                <img src={artwork} alt="artwork" style={{ marginLeft: '25vw' }} loading='lazy' />
            </div>

            <div className={className('privacy-policy-title-container')}>
                <h1>{staticTranslate('t_privacy_policy_header_title', true)}</h1>
            </div>

            <div className={className('privacy-policy-image-container')}>
                <img src={clouds} alt="cloud" loading='lazy' />
            </div>

            <div className={className('privacy-policy-content')}
                dangerouslySetInnerHTML={{
                    __html: staticTranslate('t_privacy_policy_header_description')
                }}
            />

        </div>
    )
}

export default PrivacyPolicyContent