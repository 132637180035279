import React from 'react'
import { useTranslations } from "../../../lang/TranslationHooks";

import { className } from '../../../Utils'
import { clouds, artwork } from '../../../Images'

import './AndroidPaymentTitle.css'
const AndroidPaymentTitle = () => {
    let { staticTranslate } = useTranslations();

    return (
        <>
            <div className={className('android-payment-title-wrapper')} id="section00">
                <div className={className('android-payment-artwork-container')}>
                    <img src={artwork} alt="artwork"  loading='lazy'/>
                </div>
                <div className={className('android-payment-cloud-container')}>
                    <img src={clouds} alt="cloud" loading='lazy' />
                </div>
                <div>
                    <h1 className={className('android-payment-title')}>{staticTranslate('t_android_payment_header_title', true)}</h1>
                </div>
            </div>
        </>

    )
}

export default AndroidPaymentTitle