import axios from "axios";
import { SERVER_URL } from "../../config/serverUrl";
import { store } from "..";
import { getUserToken } from "../../firebase/firebaseInit";
import {
  removeAllModals,
  SetCategories,
  SetSearchString,
  SetSelectedCategory,
  SetSelectedTred,
  SetTreds,
  SetTredsFound,
  ShowComments,
} from "../actions/chatActions";
// import { setError } from "../actions/errorActions";
import { SetNotificationsList } from "../actions/userActions";

const getCategories = async (language) => {

  return axios
    .get(`${SERVER_URL}/community/categories`, { language, admin: true })
    .then((response) => {
      store.dispatch(SetCategories(response.data));
    })
    .catch((error) => { }); //todo set error log
};

const getOneCategory = async (id) => {
  return await axios
    .get(`${SERVER_URL}/community/categories/${id}`)
    .then((response) => {
      store.dispatch(SetSelectedCategory(response.data));
    })
    .catch((error) => { }); //todo set error log
};

const searchCategories = async (text) => {
  return axios.get(`${SERVER_URL}/community/categories/search/`, { text, language: 'bg', admin: true });
};

const getThreads = async (page) => {
  return axios
    .get(`${SERVER_URL}/community/threads/?page=${page}&limit=50&order=asc`)
    .then((response) => {
    });
};

const getTredsByCategory = async (catId, page = 1, limit = 50, language) => {
  return await axios
    .get(
      `${SERVER_URL}/community/threads/by-category/?catId=${catId}&page=${page}&limit=${limit}&language=${language}`
    )
    .then((response) => {
      store.dispatch(SetTreds(response.data));
    })
    .catch((error) => { }); //todo set error log
};

const getOneThread = async (id, callback) => {
  return await axios
    .get(`${SERVER_URL}/community/threads/${id}`)
    .then((response) => {
      if (callback) {
        callback(response.data);
      } else {
        store.dispatch(SetSelectedTred(response.data));
      }
    })
    .catch((error) => {
      //   debugger;
    });
  //todo set error log
};

export const addEdditUser = async (user) => {
  return await axios({
    method: "post",
    url: `${SERVER_URL}/community/users/add-edit-user`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    data: user,
  })
    .then((response) => {
      // console.log("addEdditUser", response.data);
    })
    .catch((error) => {
      console.log("add-edit-user api fail - ", user, error);
      //dispatch(setError(`${error.code}`));
    }); //todo set error log;
};

export const getNotifications = async (uid) => {
  return await axios({
    url: `${SERVER_URL}/community/threads/followed/${uid}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
  })
    .then((response) => {
      store.dispatch(SetNotificationsList(response.data));
    })
    .catch((error) => { }); //todo set error log
};

// export const getUser = (user) => {
//   return (dispatch) => {
//     axios
//       .get(`${SERVER_URL}/community/users/get-one/${user.uid}`)
//       .then((response) => {
//         console.log("community user ", response.data);
//         getNotifications(user.uid);
//         dispatch(setUser({ ...response.data, ...user }));
//       })
//       .catch((error) => {}); //todo set error log
//   };
// };

export const createThread = async (
  title,
  description,
  category_id,
  user_id,
  language
) => {
  return await axios({
    method: "post",
    url: `${SERVER_URL}/community/threads/create`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    data: {
      title: title,
      description: description,
      category_id: category_id,
      user_id: user_id,
    },
  })
    .then((response) => {
      getTredsByCategory(category_id, 1, 25, language);
      //update followed threads
      getNotifications(user_id);
    })
    .catch((error) => { }); //todo set error log
};

export const edditThread = async (
  id,
  title,
  description,
  category_id,
  user_id,
  language
) => {
  let res = await axios({
    // url of the api endpoint (can be changed)
    url: `${SERVER_URL}/community/threads/edit`,
    method: "PUT",
    data: {
      id: id,
      title: title,
      description: description,
      category_id: category_id,
      user_id: user_id,
    },
    headers: {
      Authorization: `Bearer ${await getUserToken()}`,
    },
  })
    .then((response) => {
      //update tred answers list
      getTredsByCategory(category_id, 1, 25, language);
      getOneThread(response.data.id);
      return response.data;
    })
    .catch((error) => {
      return null;
    }); //todo set error log

  return res;
};

export const searchThread = async (categoryId, str, language) => {

  store.dispatch(SetSearchString(str));
  if (str) {
    await axios({
      method: 'get',
      url: `${SERVER_URL}/community/threads/search-thread/?title=${str}&language=${language}&catId=${categoryId}&page=1`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getUserToken()}`,
      },
    }).then((response) => {
      store.dispatch(SetTredsFound(response.data.results));

    })
      .catch((error) => {
        console.log(error);
        store.dispatch(SetTredsFound(null));
      }); //todo set error log
  } else {
    store.dispatch(SetTredsFound(null));
  }
};

export const deleteThread = async (catId, threadId, uid, language) => {
  return await axios({
    method: "put",
    url: `${SERVER_URL}/community/threads/delete`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    data: { uid: uid, threadId: threadId },
  })
    .then((response) => {
      //update treds list
      getTredsByCategory(catId, 1, 25, language);
      store.dispatch(removeAllModals());
      store.dispatch(ShowComments(false));
    })
    .catch((error) => {
      // debugger;
    }); //todo set error log
};

export const createAnswer = async (user_id, thread_id, content) => {
  return await axios({
    method: "post",
    url: `${SERVER_URL}/community/answers/create-answer`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    data: {
      user_id: user_id,
      thread_id: thread_id,
      content: content,
    },
  })
    .then((response) => {
      //update tred answers list
      getOneThread(thread_id);
      //update followed threads
      getNotifications(user_id);
    })
    .catch((error) => {
      // debugger;
      console.log(error);
    });
};

export const edditAnswer = async (id, user_id, thread_id, content) => {
  return await axios({
    method: "put",
    url: `${SERVER_URL}/community/answers/edit-answer`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    data: {
      id: id,
      user_id: user_id,
      thread_id: thread_id,
      content: content,
    },
  })
    .then((response) => {
      //update tred answers list
      getOneThread(thread_id);
    })
    .catch((error) => {
      // debugger;
    }); //todo set error log
};
export const deleteAnswer = async (uid, thread_id, answer_id) => {
  axios({
    method: "put",
    url: `${SERVER_URL}/community/answers/delete-answer`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    data: {
      uid: uid,
      thread_id: thread_id,
      answer_id: answer_id,
    },
  })
    .then((response) => {
      //update tred answers list
      getOneThread(thread_id);
    })
    .catch((error) => {
      //;
    }); //todo set error log
};
const searchThreads = (catId, text) => {
  return axios.get(
    `${SERVER_URL}/community/threads/?catId=${catId}&order=asc&title=${text}`
  );
};

export const followThread = async (follow, uid, thread_id, answersCount) => {
  return await axios({
    method: "put",
    url: `${SERVER_URL}/community/users/${follow ? "set-new-thread" : "unset-thread"
      }`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    data: {
      uid: uid,
      thread_id: thread_id,
      answersCount: answersCount,
    },
  })
    .then((response) => {
      //update notifications
      getNotifications(uid);

      //update thread answers list
      getOneThread(thread_id);
    })
    .catch((error) => {
      // debugger;
    }); //todo set error log
};
export const setViewed = async (uid, thread_id, answersCount) => {
  await axios({
    method: "put",
    url: `${SERVER_URL}/community/users/set-viewed`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getUserToken()}`,
    },
    data: {
      uid: uid,
      thread_id: thread_id,
      answersCount: answersCount,
    },
  })
    .then((response) => {
      //update notifications
      getNotifications(uid);

      //update thread answers list
      //getOneThread(thread_id);
    })
    .catch((error) => {
      // debugger;
    }); //todo set error log
};

const actions = {
  addEdditUser,

  getCategories,
  getOneCategory,
  searchCategories,

  createThread,
  getThreads,
  getTredsByCategory,
  getOneThread,
  searchThread,
  searchThreads,

  createAnswer,
  edditAnswer,
  deleteAnswer,
};

export default actions;
