import React from 'react'

import SingleContainer from './SingleContainer'
import { useTranslations } from "../../../lang/TranslationHooks";

import { className } from '../../../Utils'

import screen1 from '../assets/screen1.png'
import screen2 from '../assets/screen2.png'
import screen3 from '../assets/screen3.png'
import screen4 from '../assets/screen4.png'
import screen5 from '../assets/screen5.png'
import screen6 from '../assets/screen6.png'
import screen7 from '../assets/screen7.png'
import screen8 from '../assets/screen8.png'
import screen9 from '../assets/screen9.png'
import './AndroidPaymentContent.css'

const AndroidPaymentContent = () => {
    let { staticTranslate } = useTranslations();

    const content = [
        {
            id: 1,
            title: staticTranslate('t_android_payment_content_1_title', true),
            description: staticTranslate('t_android_payment_content_1_description'),
            imgPath: screen1
        },
        {
            id: 2,
            title: staticTranslate('t_android_payment_content_2_title', true),
            description: staticTranslate('t_android_payment_content_2_description'),
            imgPath: screen2
        },
        {
            id: 3,
            title: staticTranslate('t_android_payment_content_3_title', true),
            description: staticTranslate('t_android_payment_content_3_description'),
            imgPath: screen3
        },
        {
            id: 4,
            title: staticTranslate('t_android_payment_content_4_title', true),
            description: staticTranslate('t_android_payment_content_4_description'),
            imgPath: screen4
        },
        {
            id: 5,
            title: staticTranslate('t_android_payment_content_5_title', true),
            description: staticTranslate('t_android_payment_content_5_description'),
            imgPath: screen5
        },
        {
            id: 6,
            title: staticTranslate('t_android_payment_content_6_title', true),
            description: staticTranslate('t_android_payment_content_6_description'),
            imgPath: screen6
        },
        {
            id: 7,
            title: staticTranslate('t_android_payment_content_7_title', true),
            description: staticTranslate('t_android_payment_content_7_description'),
            imgPath: screen7
        },
        {
            id: 8,
            title: staticTranslate('t_android_payment_content_8_title', true),
            description: staticTranslate('t_android_payment_content_8_description'),
            imgPath: screen8
        },
        {
            id: 9,
            title: staticTranslate('t_android_payment_content_9_title', true),
            description: staticTranslate('t_android_payment_content_9_description'),
            imgPath: screen9
        }
    ]
    return (
        <div className={className('android-payment-content')}>
            {content.map((item) => <SingleContainer key={item.id} {...item} />)}
        </div>
    )
}

export default AndroidPaymentContent