import React, { useEffect, useRef, useState } from "react";
import "./FeiaInput.css";

const FeiaInput = ({
  defaultValue,
  prompt,
  inputHandler,
  limit,
  cyrillicErrorMsg,
  maxRows = 1,
  className = null
}) => {
  let [state, setState] = useState({ isCyrilic: true, value: defaultValue });
  let textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current.style.height = "0px";
      const scrollHeight = textAreaRef.current.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
    
      let growLimit = maxRows * 15.25;
      let limmitedHeight = scrollHeight > growLimit ? growLimit : scrollHeight;
      textAreaRef.current.style.height = limmitedHeight + "px";
    }
  }, [textAreaRef, state.value, maxRows]);

  const handleChange = (e) => {
    let val = e.target?.value;

    if (val.length > limit) {
      val = val.slice(0, limit);
    }

    let isCyrilic = checkForCyrillicLetter(val);
    setState((prev) => ({
      ...prev,
      value: val,
      ...(cyrillicErrorMsg ? { isCyrilic } : {}),
    }));

    inputHandler(val,isCyrilic);
  };

  const checkForCyrillicLetter = (value = "") => {
    let hasCyrillicLetter = String(value).match(/[а-я]/gm);
    if (value.length === 0) {
      return true;
    }
    return hasCyrillicLetter;
  };

  return (
    <div>
      <textarea
        className={ className ? className : "feiaInput"}
        ref={textAreaRef}
        rows={1}
        value={state.value}
        placeholder={prompt}
        onChange={handleChange}
      />
      <div className="underLineInfo">
        <div className="errorMsg">
          {(!state.isCyrilic && cyrillicErrorMsg) || ""}
        </div>
        {limit && <div>{`${state.value.length}/${limit}`}</div>}
      </div>
    </div>
  );
};

export default FeiaInput;
