export const GET_ALL_FAQS_BY_LANG = 'GET_ALL_FAQS_BY_LANG';
export const FETCH_FAQS_FAILED = 'FETCH_FAQS_FAILED'

const defaultState = {
    faqs: [],
    faqsErrors: null,
};

const faqReducer = (state = defaultState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_FAQS_BY_LANG:
            return {
                ...state,
                faqs: payload,
                faqsErrors: null,
            };
        case FETCH_FAQS_FAILED:
            return {
                ...state,
                faqsErrors: payload
            }

        default:
            return state;
    }
};

export default faqReducer;