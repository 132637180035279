import { className } from '../../Utils'

import googlePlay from "../../assets/google-play-new.png";
import appleStore from "../../assets/app-store-new.png";

import "./StoreSection.css";

const StoreSection = () => {

  return (
    <div className={className('store-section')}>
      <a href='https://apps.apple.com/us/app/feia-%D0%B1%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82/id1299624022?ls=1'
        target="_blank" rel="noreferrer" title="IOS store">
        <img
          src={appleStore}
          alt="Apple store"
          className="store-img"
          loading="lazy"
        />
      </a>
      <a href='https://play.google.com/store/apps/details?id=bg.feia.Pregnancy'
        target="_blank" rel="noreferrer" title="Play store">
        <img
          src={googlePlay}
          alt="Google play store"
          className="store-img"
          loading="lazy"
        />
      </a>

    </div>
  );
}

export default StoreSection;
