import Carousel from 'nuka-carousel';
import { isMobile } from 'react-device-detect';

import { useState } from "react";

import checkedNow from '../Pregnancy/assets/checkedNew.svg'
import checkedWhite from '../Pregnancy/assets/checkedNew-white.svg'
import iconXwhite from '../Pregnancy/assets/whiteXICcon.svg'

import { className, IsWindow, scrollToSection } from "../../Utils.js";

import "./AppsCarousel.css";
import CarouselMobileDots from './CarouselMobileDots/CarouselMobileDots.js';
import { useTranslations } from "../../lang/TranslationHooks";
import crown from '../../assets/crown.svg'
import { useSelector } from 'react-redux';

const AppsCarousel = ({ sectionKey }) => {
    const { language } = useSelector((state) => state.home)
    const [slideIndex, setSlideIndex] = useState(isMobile ? 0 : 1)
    let { staticTranslate } = useTranslations();

    const newData = [
        {
            id: 1,
            title: staticTranslate('t_pregnancy_price_component_title', true),
            freeFunc: staticTranslate('t_pregnancy_accordion_functioanality_title', true),
            feiaPpus: staticTranslate('t_pregnancy_accordion_important_title', true),
            miniAps: staticTranslate('t_pregnancy_accordion_miniaps_title', true),
            ios: 'https://apps.apple.com/us/app/feia-%D0%B1%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82/id1299624022?ls=1',
            android: "https://play.google.com/store/apps/details?id=bg.feia.Pregnancy"
        },
        {
            id: 2,
            duration: staticTranslate("t_pregnancy_price_component_monthly_duration", true),
            price: staticTranslate("t_pregnancy_price_component_monthly_price", true),
            freeFunc: staticTranslate('t_pregnancy_accordion_functioanality_title', true),
            feiaPpus: staticTranslate('t_pregnancy_accordion_important_title', true),
            miniAps: staticTranslate('t_pregnancy_accordion_miniaps_title', true),
            planRenewal: staticTranslate('t_pregnancy_price_component_plan_renewal', true),
            ios: 'https://apps.apple.com/us/app/feia-%D0%B1%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82/id1299624022?ls=1',
            android: "https://play.google.com/store/apps/details?id=bg.feia.Pregnancy"
        },
        {
            id: 3,
            duration: staticTranslate('t_pregnancy_price_component_yearly_duration', true),
            price: staticTranslate("t_pregnancy_price_component_yearly_price", true),
            secondPrice: staticTranslate('t_pregnancy_price_component_yearly_second_price', true),
            freeFunc: staticTranslate('t_pregnancy_accordion_functioanality_title', true),
            feiaPpus: staticTranslate('t_pregnancy_accordion_important_title', true),
            miniAps: staticTranslate('t_pregnancy_accordion_miniaps_title', true),
            planRenewal: staticTranslate('t_pregnancy_price_component_plan_renewal', true),
            bestSeller: staticTranslate('t_pregnancy_price_component_best_seller', true),
            savePercent: staticTranslate('t_pregnancy_price_component_save_percent', true),
            ios: 'https://apps.apple.com/us/app/feia-%D0%B1%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82/id1299624022?ls=1',
            android: "https://play.google.com/store/apps/details?id=bg.feia.Pregnancy"
        }
    ];

    return (
        <div id={sectionKey}>
            <div className={className('carousel')}>
                <Carousel
                    wrapAround={true}
                    slidesToShow={3}
                    dragging={isMobile ? true : false}
                    swiping={isMobile ? true : false}
                    transitionMode={isMobile ? 'scroll3d' : 'scroll'}
                    cellSpacing={isMobile ? 120 : 0}
                    withoutControls={true}
                    cellAlign='center'
                    animation={isMobile ? "zoom" : null}
                    opacityScale={1}
                    frameOverflow='false'
                    slideIndex={slideIndex}
                    slideOffset={isMobile ? IsWindow.innerWidth / 4 : 0}
                    style={{ width: isMobile ? '100vw' : '58vw' }}
                    afterSlide={slideIndex => setSlideIndex(slideIndex)}
                >
                    {newData.map((item) => (
                        <div key={'mobile' + item.title}
                            className={className('carousel-card')}
                            onClick={() => isMobile
                                ? window.location.href = window.navigator.userAgent.indexOf('Mac OS') !== -1
                                    ? item?.ios
                                    : item?.android
                                : scrollToSection('section05')}>

                            {item?.bestSeller && <div className={className('info-mark')}>
                                <p className={className('best-seller')}>&#9733;{item?.bestSeller}</p>

                                <p className={className('save-price')}>{item?.savePercent}</p>
                            </div>}

                            {item?.title && <h3 className={className('card-heading')}>{item?.title}</h3>}
                            {item?.duration && <div className={className('container-info')}>
                                <img src={crown} alt="crown" loading="lazy" className={className('carousel-img-crown')} />
                                <p className={className('card-duration')}>{item?.duration}</p>
                                <p className={className('card-price')}> {language === 'bg' ? 'BGN' : "EUR"}{item.price}</p>
                            </div>}


                            <div className={className('carousel-content')}>
                                <div className={className('carousel-func')}><img src={item?.title ? checkedWhite : checkedNow} alt={item?.freeFunc} /><p>{item?.freeFunc}</p></div>
                                <div className={className('carousel-func')}><img className={className(item?.title && 'x-icon')} src={item?.title ? iconXwhite : checkedNow} alt={item?.freeFunc} /><p>{item?.feiaPpus}</p></div>
                                <div className={className('carousel-func')}><img className={className(item?.title && 'x-icon')} src={item?.title ? iconXwhite : checkedNow} alt={item?.freeFunc} /><p>{item?.miniAps}</p></div>
                            </div>

                            <div className={className(item?.title ? "carousel-button-white" : 'carousel-button')}>
                                {staticTranslate('t_pregnancy_second_section_install_now', true)}
                            </div>
                            {item?.planRenewal &&
                                <p className={className('plan-renewal')}> *{item?.planRenewal}</p>}

                        </div>
                    ))}
                </Carousel>

                {isMobile
                    && <CarouselMobileDots data={newData} slideIndex={slideIndex} />
                }
            </div>
        </div>
    )
}

export default AppsCarousel