
import { className } from "../../Utils";

import nextBtn from '../../assets/right.svg'
import prevBtn from '../../assets/left.svg';

import './Arrows.css'

const Arrows = ({ prevSlide, nextSlide }) => {

    return (
        <div className={className('arrows')}>
            <span
                className={className('prev')}
                onClick={prevSlide}
            >
                <img
                    src={prevBtn}
                    className={className('arrows-img-prev')}
                    alt="prev button"
                />
            </span>
            <span
                className={className('next')}
                onClick={nextSlide}
            >
                <img
                    src={nextBtn}
                    className={className('arrows-img-next')}
                    alt="next button"
                />
            </span>
        </div>
    )
}

export default Arrows
