import { useState } from "react";

import { isMobile } from "react-device-detect";
import { className } from '../../../../Utils'
import { useSelector } from "react-redux";

import AccordionHeader from "./AccordionHeader";
import AccordionContentDesktop from "./AccordionContentDesktop";
import AccordionContentMobile from "./AccordionContentMobile";
import AccordionVideo from "./AccordionVideo";

import "./Accordion.css";

const Accordion = ({
    data,
    title,
    videoUrl,
    freeFunctionality
}) => {
    const [clicked, setClicked] = useState(false);
    const [isOpenVideo, SetIsOpenVideo] = useState(false);
    const { language } = useSelector((state) => state.home)
    // const toggle = index => setClicked(clicked === index && index);
    const toggle = index => {
        if (clicked === index) {
            return setClicked(null);
        }
        setClicked(index);
    };

    return (
        <main>
            <div className={className("container")}>

                <div className={className('container-accordion-video-wrapper')}>
                    {language === 'bg' &&
                        <AccordionVideo
                            videoUrl={videoUrl}
                            isOpenVideo={isOpenVideo}
                            SetIsOpenVideoHandler={SetIsOpenVideo}
                            title={title}
                        />
                    }
                </div>

                <section className={className('info')}>
                    <AccordionHeader title={title} SetIsOpenVideoHandler={SetIsOpenVideo} />
                    <div>
                        {data.map((item) => (
                            isMobile
                                ? <AccordionContentMobile
                                    id={item.id}
                                    itemTitle={item.title}
                                    contentMobile={item.contentMobile}
                                    key={item.id}
                                    toggle={toggle}
                                    clicked={clicked}
                                    freeFunctionality={freeFunctionality}
                                />
                                : <AccordionContentDesktop
                                    key={item.id}
                                    itemTitle={item.title}
                                    content={item.content}
                                    freeFunctionality={freeFunctionality}
                                />
                        ))}
                    </div>
                    {
                        data.length % 2 !== 0
                        && <div className={className('accordion-line-end')} />
                    }

                </section>
            </div>
        </main>
    );

};
export default Accordion;
