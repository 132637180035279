import React, { useEffect, useState } from 'react';
import { useTranslations } from "../../../lang/TranslationHooks";
import GlobalLoader from '../../GlobalLoader/GlobalLoader';
import { className } from '../../../Utils';
import { clouds, artwork } from '../../../Images';
import './TermsContent.css';
import { getTerms } from '../../../databaseUtil';

import ErrorContainer from '../../common/ErrorContainer/ErrorContainer';
import { useSelector } from 'react-redux';

const TermsContent = () => {
    let { staticTranslate } = useTranslations();
    const { language } = useSelector((state) => state.home)
    const [terms, setTerms] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTerms = async () => {
            try {
                const items = await getTerms(language);
                if (items.code === 404) {
                    setError(true);
                } else {
                    setTerms(items);
                }

            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        const timer = setTimeout(fetchTerms, 1000);
        return () => clearTimeout(timer);
    }, [language]);

    return (
        <div className={className('terms-wrapper')} id="section00">
            <div style={{ backgroundColor: "#f8faff" }}>
                <div className={className('terms-artwork')}>
                    <img src={artwork} alt="artwork" style={{ marginLeft: '25vw' }} loading='lazy' />
                </div>
                <div className={className('terms-title-container')}>
                    <h1 className={className('terms-header-title')}>
                        {staticTranslate('t_terms_header_title', true)}
                    </h1>
                    <p className={className('terms-content-info')}>
                        {staticTranslate('t_terms_header_description', true)}
                    </p>
                </div>
            </div>
            <div className={className('terms-image-container')}>
                <img src={clouds} alt="cloud" loading='lazy' />
            </div>
            {loading ? (
                <GlobalLoader showSpinner={true} />
            ) : error ? (
                <ErrorContainer />
            ) : (
                <div className={className('terms-content')}
                    dangerouslySetInnerHTML={{
                        __html: terms?.name !== 'NotFoundError' ? terms?.name : ""
                    }}
                />
            )}
        </div>
    );
};

export default TermsContent;
