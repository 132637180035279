import React from 'react'

import { className, removEmptyParagraph } from '../../../Utils';
import { faq_Arrow, faq_Arrow_up } from '../../../Images';

import './SingleFAQItem.css'

const SingleFAQItem = ({ id, title, content, clicked, toggle, index }) => {
    return (
        <div onClick={() => toggle(id)} className={className('single-faq-item-wrapper') + ' ' + className(`${clicked === id && 'single-faq-item-wrapper-active'}`)}>
            <div className={className('single-faq-container') + ' ' + className(`${clicked === id && 'single-faq-container-active'}`)}>
                <p >
                    {index + 1}. {title}
                </p>
                <div className={className(clicked === id ? 'faq-arrow-container-active' : 'faq-arrow-container')}>
                    <img src={clicked === id ? faq_Arrow_up : faq_Arrow} alt="arrow"

                    />
                </div>
            </div>
            {
                clicked === id
                && <div
                    className={className('single-faq-item-content')}
                    dangerouslySetInnerHTML={{
                        __html: removEmptyParagraph(content)
                    }}
                />
            }
        </div>
    )
}

export default SingleFAQItem