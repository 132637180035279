import React from 'react'
import { className } from '../../../Utils';
import greenArrow from '../assets/green_arrow.png'
import reversedArrow from '../assets/reversed_green_arrow.png'

import './SingleContainer.css'
const SingleContainer = ({ title, description, imgPath, id }) => {
    return (
        <div className={className('ios-single-wrapper')}>
            <div className={className('ios-single-content')}>
                <h3 className={className('ios-single-container-title') + ' ' + className(`ios-single-container-title-${id}`)}>{title}</h3>
                <p className={className('ios-single-container-description')}
                    dangerouslySetInnerHTML={{
                        __html: description
                    }}
                />
            </div>
            <div className={className('ios-arrow-container')}>
                <img src={id % 2 === 0 ? reversedArrow : greenArrow} alt="arrow" loading='lazy' />
            </div>

            <div className={className('ios-single-image-container')}>
                <img src={imgPath} alt={title}  loading='lazy'/>
            </div>

        </div>
    )

}

export default SingleContainer