import React from 'react'

import { className } from '../../../../Utils'

import mail from '../../assets/mail.svg'
import facebook from '../../assets/facebook-logo.svg'
import instagram from '../../assets/instagram.svg'
import linkedin from '../../assets/linkedin.png'
import questions from '../../assets/questions.png'
import { useTranslations } from "../../../../lang/TranslationHooks";

import './ContactsInfo.css'
import { useSelector } from 'react-redux'


const ContactsInfo = () => {
    let { staticTranslate } = useTranslations();
    const { language } = useSelector((state) => state.home)


    const data = [
        {
            id: 1,
            image: facebook,
            urlPath: language === 'bg' ? "https://www.facebook.com/FeiaBulgaria" : "https://www.facebook.com/gaiapregnancyapp/",
            title: 'facebook icon'
        },
        {
            id: 2,
            image: instagram,
            urlPath: language === 'bg' ? "https://www.instagram.com/feia_bulgaria" : "https://www.instagram.com/gaia_pregnancy_app",
            title: 'instagram icon'
        },
        {
            id: 3,
            image: linkedin,
            urlPath: language === 'bg' ? 'https://www.linkedin.com/company/feia-bulgaria/' : 'https://www.linkedin.com/company/gaia-family-app/',
            title: 'linkedin icon'
        },
    ]

    return (
        <div className={className('contacts-info')}>
            <div className={className('contacts-info-background-wrapper')}>

                <div className={className('contacs-info-wrapper')}>
                    <p className={className('contacts-title')}>
                        {staticTranslate('t_contacts_title', true)}
                    </p>
                    <div className={className('contacs-email-container')}>
                        <img src={mail} alt="mail" />
                        <p>{staticTranslate('t_contacts_email', true)}: <a className='contacts-mail' href={`mailto:${staticTranslate('t_contacts_email_address', true)}`}>
                            {staticTranslate('t_contacts_email_address', true)}
                        </a>
                        </p>
                    </div>
                </div>
                <div className={className('contacts-icons-wrapper')}>
                    <div className={className("constacts-icons-container")}>
                        {data.map((item) => (
                            <a key={item.id} href={item.urlPath} target='_blank' rel="noreferrer"  >
                                <img src={item.image} alt={item.title} />
                            </a>
                        )
                        )}
                    </div>
                    <div className={className('contacts-question-image-container')}>
                        <img src={questions} alt="questions" className={className('contacts-question-image')} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactsInfo