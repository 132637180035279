
import { weaning, pregnancy, development } from "../../Images";
import slide1 from './assets/pregnancySilder/webp/slide1.webp'
import slide2 from './assets/pregnancySilder/webp/slide2.webp'
import slide3 from './assets/pregnancySilder/webp/slide3.webp'
import slide4 from './assets/pregnancySilder/webp/slide4.webp'
import slide5 from './assets/pregnancySilder/webp/slide5.webp'
import slide6 from './assets/pregnancySilder/webp/slide6.webp'
import slide7 from './assets/pregnancySilder/webp/slide7.webp'
import slide8 from './assets/pregnancySilder/webp/slide8.webp'
import slide9 from './assets/pregnancySilder/webp/slide9.webp'
import slide10 from './assets/pregnancySilder/webp/slide10.webp'
import slide11 from './assets/pregnancySilder/webp/slide11.webp'
import slide12 from './assets/pregnancySilder/webp/slide12.webp'
import slide13 from './assets/pregnancySilder/webp/slide13.webp'
import slide14 from './assets/pregnancySilder/webp/slide14.webp'
import slide15 from './assets/pregnancySilder/webp/slide15.webp'
import slide16 from './assets/pregnancySilder/webp/slide16.webp'

import sectionImportant from '../../assets/videos pregnancy/section-important.mp4'
import sectionMini from '../../assets/videos pregnancy/section-mini.mp4'
import feiapregnancyFm from './assets/feiapregnancy-fm.mp4'
import free_functionality from './assets/free.mp4'

import familyBg from './assets/newContent/family-BG.png'
import familyEn from './assets/newContent/family-EN.png'
import familyDe from './assets/newContent/family-DE.png'
import comparisionBG from './assets/newContent/comparison-BG.mp4'
import comparisionEn from './assets/newContent/comparison-EN.mp4'
import comparisionDE from './assets/newContent/comparison-DE.mp4'
import calendarBg from './assets/newContent/calendar-BG.png'
import calendarEN from './assets/newContent/calendar-EN.png'
import calendarDE from './assets/newContent/calendar-DE.png'
import notificationsBg from './assets/newContent/notifications-BG.png'
import notificationsEN from './assets/newContent/notifications-EN.png'
import notificationsDE from './assets/newContent/notifications-DE.png'
import searchBg from './assets/newContent/search-BG.png'
import searchEN from './assets/newContent/search-EN.png'
import searchDE from './assets/newContent/search-DE.png'
import bellyBg from './assets/newContent/belly-photo-BG.png'
import bellyEn from './assets/newContent/belly-photo-EN.png'
import bellyDE from './assets/newContent/belly-photo-DE.png'
import IBecomeAMotherBg from './assets/newContent/I-become-a-mother-BG.png'
import IBecomeAMotherEN from './assets/newContent/I-become-a-mother-EN.png'
import IBecomeAMotherDE from './assets/newContent/I-become-a-mother-DE.png'
import newComparisionBg from './assets/newContent/comparison-EN-new.mp4'


import enSlide1 from './assets/en/1.jpg';
import enSlide2 from './assets/en/2.jpg'
import enSlide3 from './assets/en/3.jpg'
import enSlide4 from './assets/en/4.jpg'
import enSlide5 from './assets/en/5.jpg'
import enSlide6 from './assets/en/6.jpg'
import enSlide7 from './assets/en/7.jpg'
import enSlide8 from './assets/en/8.jpg'
import enSlide9 from './assets/en/9.jpg'
import enSlide10 from './assets/en/10.jpg'
import enSlide11 from './assets/en/11.jpg'
import enSlide12 from './assets/en/12.jpg'
import enSlide13 from './assets/en/13.jpg'
import enSlide14 from './assets/en/14.jpg'
import enSlide15 from './assets/en/15.jpg'

import deSlide1 from './assets/de/1.webp'
import deSlide2 from './assets/de/2.webp'
import deSlide3 from './assets/de/3.webp'
import deSlide4 from './assets/de/4.webp'
import deSlide5 from './assets/de/5.webp'
import deSlide6 from './assets/de/6.webp'
import deSlide7 from './assets/de/7.webp'
import deSlide8 from './assets/de/8.webp'
import deSlide9 from './assets/de/9.webp'
import deSlide10 from './assets/de/10.webp'
import deSlide11 from './assets/de/11.webp'

export const sections = [
    {
        key: "section00",
        title: "ОТКРИЙ ОТГОВОРИТЕ ИМ В МОБИЛНО ПРИЛОЖЕНИЕ FEIA БРЕМЕННОСТ",
        content: "ЕДНА БРЕМЕННА ЖЕНА СИ ЗАДАВА СРЕДНО МЕЖДУ 5 И 500 ВЪПРОСА НА ДЕН",
        button: "НАУЧИ ПОВЕЧЕ",
    },
    {
        key: "section01",
        title: `ВСИЧКО НЕОБХОДИМО <br/>ЗА БЪДЕЩАТА МАЙКА<br/> ПО ГЕСТАЦИОННИ СЕДМИЦИ`,
        content: [
            {
                id: 1,
                text: `Как се променя <strong>вашето тяло през всяка гестационна седмица;</strong>`
            },
            {
                id: 2,
                text: `<strong>Как се развива </strong> вашето бебе;`
            },
            {
                id: 3,
                text: 'Полезни <strong>съвети</strong>, съобразени с етапа на бременността, в който се намирате;'
            },
            {
                id: 4,
                text: 'Отговор на <strong>въпросите</strong>, които ви вълнуват през съответната седмица;'
            },
            {
                id: 5,
                text: 'Списък със <strong>задачите</strong>, които трябва да свършите, за да бъдете подготвена и спокойна;'
            },
            {
                id: 6,
                text: 'Съвети за <strong>здравословно и балансирано хранене.</strong>'
            }
        ],
        sliderContent: [
            {
                id: 1,
                url: feiapregnancyFm
            },
            {
                id: 2,
                url: slide1
            },
            {
                id: 3,
                url: slide2
            },
            {
                id: 4,
                url: slide3
            },
            {
                id: 5,
                url: slide4
            },
            {
                id: 6,
                url: slide5
            },
            {
                id: 7,
                url: slide6
            },
            {
                id: 8,
                url: slide7
            },
            {
                id: 9,
                url: slide8
            },
            {
                id: 10,
                url: slide9
            },
            {
                id: 11,
                url: slide10
            },
            {
                id: 12,
                url: slide11
            },
            {
                id: 13,
                url: slide12
            },
            {
                id: 14,
                url: slide13
            },
            {
                id: 15,
                url: slide14
            },
            {
                id: 16,
                url: slide15
            },
            {
                id: 17,
                url: slide16
            },
        ]
    },
    {
        key: "section02",
        title: "Какво ЩЕ ПОЛУЧИШ?",
        content: 'Какво ще получиш, отключвайки секция "Важни" и секция "Мини приложения".',
        firstAccordionTitle: 'функционалност',
        secondAccordionTitle: '',
        functioanality: [
            {
                title: 'БЕЗПЛАТНИ ФУНКЦИОНАЛНОСТИ',
                videoUrl: free_functionality,
                data: [
                    {
                        id: 2,
                        title: 'МАМА',
                        content: `Какво се случва с майката през всяка гестационна седмица.`,
                        contentMobile: `Какво се случва с майката през всяка гестационна седмица.`,
                    },
                    {
                        id: 3,
                        title: 'БЕБЕ',
                        content: 'Какво се случва с бебето през всяка гестационна седмица.',
                        contentMobile: 'Какво се случва с бебето през всяка гестационна седмица.'
                    },
                    {
                        id: 4,
                        title: 'РАЗМЕР НА БЕБЕТО',
                        content: 'Ще можете да добиете представа, колко е голямо бебето ви във всяка гестационна седмица',
                        contentMobile: 'Ще можете да добиете представа, колко е голямо бебето ви във всяка гестационна седмица'
                    },
                    {
                        id: 5,
                        title: 'ХРАНЕНЕ',
                        content: `Какво може да се промени във вкуса на бременната.<br/> С какво може да се храни и с какво – не, за съответната гестационна седмица.`,
                        contentMobile: `Какво може да се промени във вкуса на бременната. С какво може да се храни <br/> и с какво – не, за съответната гестационна седмица.`
                    },
                    {
                        id: 6,
                        title: 'КАЛЕНДАР',
                        content: 'Дневник на бременността. Записвайте си часовете за преглед, симптомите, какви лекарствата приемате и др',
                        contentMobile: 'Дневник на бременността. Записвайте си часовете за преглед, симптомите, какви лекарствата приемате и др'
                    },
                    {
                        id: 7,
                        title: 'СТАНАХ МАЙКА',
                        content: 'Картичка, с която можете да споделите с близки и приятели щастливия момент',
                        contentMobile: 'Картичка, с която можете да споделите с близки и приятели щастливия момент'

                    },
                    {
                        id: 8,
                        title: 'СЪВЕТИ',
                        content: `За съответната гестационна седмица – пр. Какво да промените в ежедневието;<br/> Време е да си запишете час за втора фетална морфология; и др.`,
                        contentMobile: `За съответната гестационна седмица – пр. Какво да промените в ежедневието;<br/> Време е да си запишете час за втора фетална морфология; и др.`
                    }, {
                        id: 9,
                        title: 'ВЪПРОСИ',
                        content: 'Отговор на част от въпросите, които могат да възникнат у бременната жена.<br/> Пример: Колко често трябва да усещам бебето (17-та г.с).',
                        contentMobile: 'Отговор на част от въпросите, които могат да възникнат у бременната жена. Пример: Колко често трябва да усещам бебето (17-та г.с).',
                    },
                    {
                        id: 10,
                        title: 'ЗАДАЧИ',
                        content: 'Свързани с конкретната гестационна седмица.',
                        contentMobile: 'Свързани с конкретната гестационна седмица.',
                    },
                    {
                        id: 11,
                        title: 'FEIA ОБЩЕСТВО',
                        content: `Споделете вълненията си и създайте нови приятелства с жени, очакващи своята рожба,<br/> също като вас. Забавлявайте се докато обменяте опит или търсите съвет.`,
                        contentMobile: `Споделете вълненията си и създайте нови приятелства с жени, очакващи своята рожба, също като вас. Забавлявайте се докато обменяте опит или търсите съвет.`
                    }
                ]
            }

        ],
        sectionImportant: [
            {
                title: 'СЕКЦИЯ "FEIA Plus"',
                videoUrl: sectionImportant,
                data: [
                    {
                        id: 1,
                        title: 'БЕЗОПАСНО ЛИ Е?',
                        content: `Списък с безопасни или не – лекарства, ежедневни дейности и предмети, спорт,<br/> продукти, процедури, вещества и мн др.`,
                        contentMobile: `Списък с безопасни или не – лекарства, ежедневни дейности и предмети, спорт, продукти, процедури, вещества и мн др.`
                    },
                    {
                        id: 2,
                        title: 'ЗАКОНИ',
                        content: `Полезни извадки от българското законодателство, касаещи бременната жена:<br/> права, условия на труд, помощи, парични обезщетения и др.`,
                        contentMobile: `Полезни извадки от българското законодателство, касаещи бременната жена: права, условия на труд, помощи, парични обезщетения и др.`
                    },
                    {
                        id: 3,
                        title: 'ПРЕГЛЕДИ',
                        content: `Информация по тримесечие и гестационна седмица и въпроси към лекаря,<br/> съобразени с периода от бременността.`,
                        contentMobile: `Информация по тримесечие и гестационна седмица и въпроси към лекаря, съобразени с периода от бременността.`
                    },
                    {
                        id: 4,
                        title: 'СИМПТОМИ',
                        content: `Подробен списък с вероятни симптоми на бременността и тяхното обяснение.`,
                        contentMobile: `Подробен списък с вероятни симптоми на бременността и тяхното обяснение.`
                    },
                    {
                        id: 5,
                        title: 'СТАТИИ',
                        content: 'Тематични блог статии.',
                        contentMobile: 'Тематични блог статии.',
                    },
                    {
                        id: 6,
                        title: 'СЕМЕЙНИ ЗАДАЧИ',
                        content: 'Подготовка на дома и семейството за идването на бебето.',
                        contentMobile: 'Подготовка на дома и семейството за идването на бебето.'
                    },
                    {
                        id: 7,
                        title: 'СЕМЕЕН ПРОФИЛ',
                        content: 'Споделяйте всичко с партньора си – задачи, списъци и др',
                        contentMobile: 'Споделяйте всичко с партньора си – задачи, списъци и др'
                    },
                    {
                        id: 8,
                        title: 'РЕЦЕПТИ',
                        content: 'Подбрани авторски рецепти подходящи за бременни жени.',
                        contentMobile: 'Подбрани авторски рецепти подходящи за бременни жени.'
                    },
                    {
                        id: 9,
                        title: 'БЛАНКИ И БРОШУРИ',
                        content: `Готови за принтиране формуляри, молби и брошури свързани със здравеопазване,<br/> раждане и майчинство. Информация от болнични институции.`,
                        contentMobile: `Готови за принтиране формуляри, молби и брошури свързани със здравеопазване, раждане и майчинство. Информация от болнични институции.`
                    },
                    {
                        id: 10,
                        title: 'РЕЧНИК',
                        content: 'Речник на медицинските термини, с които може да се сблъска бременната.',
                        contentMobile: 'Речник на медицинските термини, с които може да се сблъска бременната.'
                    }

                ]
            }
        ],
        miniApps: [
            {
                title: 'СЕКЦИЯ "МИНИ ПРИЛОЖЕНИЯ"',
                videoUrl: sectionMini,
                data: [
                    {
                        id: 1,
                        title: 'БРОЯЧ НА РИТАНИЯТА',
                        content: 'Позволява лесно да следите здравословното състояние и подвижността на бебето.',
                        contentMobile: 'Позволява лесно да следите здравословното състояние и подвижността на бебето.'
                    },
                    {
                        id: 2,
                        title: 'МОЦАРТ ЗА БЕБЕТА',
                        content: 'Открийте вълшебната сила на класическата музика за развитието на вашето бебе <br/>със селекция от опитен музикотерапевт.',
                        contentMobile: 'Открийте вълшебната сила на класическата музика за развитието на вашето бебе със селекция от опитен музикотерапевт.',

                    },
                    {
                        id: 3,
                        title: 'ПИЙ ВОДА',
                        content: 'Добрата хидратация по време на бременността е изключително важна.<br/> Вграденото напомняне, ще ви помогне да поддържате добър воден баланс.',
                        contentMobile: 'Добрата хидратация по време на бременността е изключително важна. Вграденото напомняне, ще ви помогне да поддържате добър воден баланс.'
                    },
                    {
                        id: 4,
                        title: 'УПРАЖНЕНИЯ НА КЕГЕЛ',
                        content: 'Не само ще се подготвите за естественото раждане, но и ще се възстановите по-бързо след<br/> естествено или оперативно израждане. Редовното практикуване подобрява и сексуалния живот.',
                        contentMobile: 'Не само ще се подготвите за естественото раждане, но и ще се възстановите по-бързо след естествено или оперативно израждане. Редовното практикуване подобрява и сексуалния живот.'

                    },
                    {
                        id: 5,
                        title: 'УПРАЖНЕНИЯ С ТОПКА',
                        content: 'Видео уроци за тонус, правещи бременността по-лека, а естественото раждане – по-щадящо.<br/> С тези упражнения ще се върнете лесно към пълноценен и активен живот след раждането.',
                        contentMobile: 'Видео уроци за тонус, правещи бременността по-лека, а естественото раждане – по-щадящо С тези упражнения ще се върнете лесно към пълноценен и активен живот след раждането.'
                    },
                    {
                        id: 6,
                        title: 'ЙОГА ЗА БРЕМЕННИ',
                        content: 'Нека тялото и умът ви бъдат в хармония през бременността.<br/> Йогата ще облекчи стреса и дискомфорта и ще стимулира имунната ви система.',
                        contentMobile: 'Нека тялото и умът ви бъдат в хармония през бременността.<br/> Йогата ще облекчи стреса и дискомфорта и ще стимулира имунната ви система.'
                    },
                    {
                        id: 7,
                        title: 'КРАЧКОМЕР',
                        content: 'Движението е важно за вас и ние ще ви помогнем да следите своята физическа активност.',
                        contentMobile: 'Движението е важно за вас и ние ще ви помогнем да следите своята физическа активност.'
                    },
                    {
                        id: 8,
                        title: 'БРОЯЧ НА КОНТРАКЦИИ',
                        content: 'Проследява честотата и продължителността на контракциите и е незаменим помощник<br/> на всяка бъдеща майка, който ще индикира възможно наближаващо раждане.',
                        contentMobile: 'Проследява честотата и продължителността на контракциите и е незаменим помощник на всяка бъдеща майка, който ще индикира възможно наближаващо раждане.'
                    },
                    {
                        id: 9,
                        title: 'БЕБЕШКИ ИМЕНА',
                        content: 'Едно от най–важните решения. Нашият подробен списък от традиционни и модерни имена<br/> ще ви улесни.',
                        contentMobile: 'Едно от най–важните решения. Нашият подробен списък от традиционни и модерни имена ще ви улесни.'
                    },
                    {
                        id: 10,
                        title: 'ВАШЕТО ТЕГЛО',
                        content: 'Изпитвате ли непрестанен глад? Следете дали поддържате здравословни килограми по време на<br/> бременността. Защото не е важно да се храните за двама, а да се храните правилно.',
                        contentMobile: 'Изпитвате ли непрестанен глад? Следете дали поддържате здравословни килограми по време на бременността. Защото не е важно да се храните за двама, а да се храните правилно.'
                    },
                    {
                        id: 11,
                        title: 'СНИМАЙ КОРЕМА',
                        content: 'Запечатайте незабравимите моменти от растежа на бебето във вашия корем. Запазете<br/> спомените за себе си или ги споделете с приятели.',
                        contentMobile: 'Запечатайте незабравимите моменти от растежа на бебето във вашия корем. Запазете спомените за себе си или ги споделете с приятели.'
                    },

                ]
            }
        ],
    },
    {
        key: "section03",
        title: "НАШИТЕ ПРИЛОЖЕНИЯ",
        content: "Cъздадени с грижа за теб. Цялата информация, от която се нуждаеш, на едно място. Винаги под ръка и само на клик разстояние.",
        innerPages: [
            {
                title: "FEIA Бременност",
                logo: pregnancy,
                key: "pregnancy",
                info: "Една бременна жена си задава средно между 5 и 500 въпроса на ден. Oткрий отговорите им в мобилно приложение FEIA Бременност.",
                pageName: 'https://feia-643a9--v1-0-38-8r3nuf9v.web.app/pregnancy'

            },
            {
                title: "FEIA Захранване",
                logo: weaning,
                key: "weaning",
                info: "Правилният хранителен режим е от първостепенно значение за растежа и развитието на бебето ви и той започва от първия ден на захранването.",
                pageName: 'https://feia.bg/apps/weaning/'

            },
            {
                title: "FEIA Детско развитие",
                key: "ChildDevelopment",
                logo: development,
                info: "Следете умственото и емоционално състояние на детето си с „чек лист“ за най-важните моменти от развитието му през първите 5 години от живота му.",
                pageName: 'https://feia.bg/apps/childsdevelopment/'

            }
        ]
    },
    {
        key: "section04",
        title: 'FEIA БЛОГ',
        subTitle: 'Интересни и актуални теми, избрани за теб от FEIA Блог.',
    },
]


export const rating = [
    {
        id: 1,
        text: `"Много ви благодарим! Информацията от приложението ме държеше спокойна по време на бременноста и с интерес<br/> очаквах и четях всички промени при мен и Бебка през различните гестационни седмици. А в приложенията, ариите са<br/> ни любимите, не само по време на бременноста, а и след нея!! Бебка ги помни и много се успокоява като ги слушаме."`,
        textMobile: `"Много ви благодарим! Информацията от<br/> приложението ме държеше спокойна по<br/> време на бременноста и с интерес очаквах <br/> и четях всички промени при мен и Бебка<br/> през различните гестационни седмици.<br/> А в приложенията, ариите са ни любимите, <br/>не само по време на бременноста, <br/>а и след нея!! Бебка ги помни и много се <br/>успокоява като ги слушаме."`,
        author: 'Йоланда Йорданова'
    },
    {
        id: 2,
        text: `"Изключително удобно, приятно, разнообразно и полизно приложение!<br/> Освен бъдеща майка съм и лекар и личи, че информацията се поднася с мисъл<br/> и отговорност към четящите!"`,
        textMobile: `"Изключително удобно, <br/>приятно, разнообразно<br/> и полизно приложение!<br/> Освен бъдеща майка съм и <br/>лекар и личи, че информацията се <br/>поднася с мисъл<br/> и отговорност към<br/> четящите!"`,
        author: 'Звездина Горанова'
    },
    {
        id: 3,
        text: `"Здравейте! Използвам Вашето приложение от началото на бременността си и съм много доволна от него!<br/> Благодаря Ви за цялата информацията, която ни давате чрез него!<br/> Наистина ми беше изключително полезна!"`,
        textMobile: `"Здравейте!<br/> Използвам Вашето приложение<br/> от началото на бременността си <br/>и съм много доволна от него!<br/> Благодаря Ви за цялата информацията,<br/> която ни давате чрез него!<br/> Наистина ми беше<br/> изключително полезна!"`,
        author: 'Кристина Дончева'
    },
    {
        id: 4,
        text: `"Здравейте, Feya! Благодаря Ви за това, че сте разработили толкова добре приложението си.<br/> Използвах го по време на цялата ми бременност, беше наистина вълнуващо да отброява<br/> оставащите седмици :))) Поздрави!"`,
        textMobile: `"Здравейте, Feya! <br/>Благодаря Ви за това,<br/> че сте разработили толкова<br/> добре приложението си.<br/> Използвах го по време<br/> на цялата ми бременност,<br/> беше наистина вълнуващо да<br/> отброява оставащите седмици :))) <br/>Поздрави!"`,
        author: 'Радина Думанян'
    },
    {
        id: 5,
        text: `"Много полезно приложение, което носи спокойствие и увереност. Информацията е сбита, ясна, разделена на теми,<br/> така че всеки може бързо да намири каквото му е нужно и му представлява интерес. Има информация за всички <br/>възможни въпроси, които вълнуват една бременна. Препоръчвам!"`,
        textMobile: `"Много полезно приложение, което носи <br/>спокойствие и увереност.<br/> Информацията е сбита, ясна,<br/> разделена на теми, така че всеки може <br/>бързо да намири каквото му е нужно<br/> и му представлява интерес.<br/> Има информация за всички възможни<br/> въпроси, които вълнуват една бременна.<br/> Препоръчвам!"`,
        author: 'Велислава Велкова'
    },
    {
        id: 6,
        text: `"Много полезно приложение.Спестява ми много време и търсене на информация.<br/> Тук има информация за цялата бременност по седмици,изследвания,документи,други приложения и т.н.<br/> Много съм доволна.Заслужава си инвестицията."`,
        textMobile: `"Много полезно приложение.<br/>Спестява ми много време и търсене<br/> на информация.<br/> Тук има информация за цялата бременност<br/> по седмици,изследвания,документи,други<br/> приложения и т.н.<br/> Много съм доволна.<br/>Заслужава си инвестицията."`,
        author: 'Галина Цанева'
    },
    {
        id: 7,
        text: `"Страхотно приложние. Беше ми много полезно през цялата бременност. В него може да откриете всичко свързано<br/> с бременноста,съвети,рецепти, да следите в коя седмица от бременноста сте,развитието на плода,консултации<br/> с вашият АГ,общ чат и какво ли още не! Препоръчвам!"`,
        textMobile: `"Страхотно приложние.<br/> Беше ми много полезно през цялата<br/> бременност. В него може да откриете<br/> всичко свързано с<br/> бременноста,съвети,рецепти, да следите<br/> в коя седмица от бременноста<br/> сте,развитието на плода,консултации с<br/> вашият АГ,общ чат и какво ли още не!<br/> Препоръчвам!"`,
        author: 'Мариела Димовска'
    },
    {
        id: 8,
        text: `"Много полезно приложение, мисля, че всяка бременна<br/> трябва да го има!"`,
        textMobile: `"Много полезно приложение, <br/>мисля, че всяка бременна<br/> трябва да го има!"`,
        author: 'Габриела Георгиева'
    }
]



export const sliderData = {
    bg: [
        {
            id: 1,
            url: feiapregnancyFm
        },
        {
            id: 2,
            url: slide1
        },
        {
            id: 3,
            url: slide2
        },
        {
            id: 4,
            url: slide3
        },
        {
            id: 5,
            url: slide4
        },
        {
            id: 6,
            url: slide5
        },
        {
            id: 7,
            url: slide6
        },
        {
            id: 8,
            url: slide7
        },
        {
            id: 9,
            url: slide8
        },
        {
            id: 10,
            url: slide9
        },
        {
            id: 11,
            url: slide10
        },
        {
            id: 12,
            url: slide11
        },
        {
            id: 13,
            url: slide12
        },
        {
            id: 14,
            url: slide13
        },
        {
            id: 15,
            url: slide14
        },
        {
            id: 16,
            url: slide15
        },
        {
            id: 17,
            url: slide16
        },
    ],
    en: [
        {
            id: 1,
            url: enSlide1
        },
        {
            id: 2,
            url: enSlide2
        },
        {
            id: 3,
            url: enSlide3
        },
        {
            id: 4,
            url: enSlide4
        },
        {
            id: 5,
            url: enSlide5
        },
        {
            id: 6,
            url: enSlide6
        },
        {
            id: 7,
            url: enSlide7
        },
        {
            id: 8,
            url: enSlide8
        },
        {
            id: 9,
            url: enSlide9
        },
        {
            id: 10,
            url: enSlide10
        },
        {
            id: 11,
            url: enSlide11
        },
        {
            id: 12,
            url: enSlide12
        },
        {
            id: 13,
            url: enSlide13
        },
        {
            id: 14,
            url: enSlide14
        },
        {
            id: 15,
            url: enSlide15
        },
    ],
    de: [
        {
            id: 1,
            url: deSlide1
        },
        {
            id: 2,
            url: deSlide2
        },
        {
            id: 3,
            url: deSlide3
        },
        {
            id: 4,
            url: deSlide4
        },
        {
            id: 5,
            url: deSlide5
        },
        {
            id: 6,
            url: deSlide6
        },
        {
            id: 7,
            url: deSlide7
        },
        {
            id: 8,
            url: deSlide8
        },
        {
            id: 9,
            url: deSlide9
        },
        {
            id: 10,
            url: deSlide10
        },
        {
            id: 11,
            url: deSlide11
        }

    ],
}

export const newContentImages = {
    familyBg, familyEn, familyDe, comparisionBG, comparisionEn, comparisionDE, calendarBg, calendarEN, calendarDE,
    notificationsBg, notificationsEN, notificationsDE, searchBg, searchDE, searchEN, bellyBg, bellyDE, bellyEn,
    IBecomeAMotherBg, IBecomeAMotherEN, IBecomeAMotherDE, newComparisionBg
}
