import React, { useState, useEffect } from 'react';
import SingleFAQItem from './SingleFAQItem';
import { className } from '../../../Utils';
import { useTranslations } from "../../../lang/TranslationHooks";
import { useSelector, useDispatch } from 'react-redux';
import { getFaqByLang } from '../../../store/api/faq';
import { clouds, artwork } from '../../../Images';
import ErrorContainer from '../../common/ErrorContainer/ErrorContainer'

import './FAQHeader.css';
import GlobalLoader from '../../GlobalLoader/GlobalLoader';

const FAQHeader = () => {
    let { staticTranslate } = useTranslations();
    const dispatch = useDispatch();
    const { faqs, faqsErrors } = useSelector((state) => state.faqs) || { faqs: [] };
    const { language } = useSelector((state) => state.home);
    const [loading, setLoading] = useState(true);
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if (clicked === index) {
            return setClicked(null);
        }
        setClicked(index);
    };

    useEffect(() => {
        const fetchFAQs = () => {
            dispatch(getFaqByLang(language));
            // After dispatching, set loading to false after 1000ms (1 second)
            const timer = setTimeout(() => setLoading(false), 1000);
            return () => clearTimeout(timer); // Cleanup the timer
        };
        fetchFAQs();
    }, [dispatch, language]);

    return (
        <div className={className('faq-header-wrapper')} id="section00">
            <div className={className('artwork-container')}>
                <img src={artwork} alt="artwork" style={{ marginLeft: '25vw' }} loading='lazy' />
            </div>
            <div>
                <h1 className={className('faq-header-title')}>
                    {staticTranslate('t_faq_header_title', true)}
                </h1>
                <div className={className('fea-header-image-container')}>
                    <img src={clouds} alt="cloud"  loading='lazy'/>
                </div>
            </div>
            <div className={className('faq-single-item-container')} >
                {/* Show fairy gif if loading is true */}
                {loading && (
                    <GlobalLoader showSpinner={loading} />
                )}
                {/* Render errors if present */}
                {!loading && faqsErrors && <ErrorContainer />
                }
                {/* Render FAQs if loaded and no errors */}
                {!loading && !faqsErrors && (
                    <>
                        {faqs.length === 0 ? (
                            <div>No FAQs available</div>
                        ) : (
                            faqs.map((item, index) => (
                                <SingleFAQItem
                                    key={item.id}
                                    {...item}
                                    toggle={toggle}
                                    clicked={clicked}
                                    setClicked={setClicked}
                                    index={index}
                                />
                            ))
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default FAQHeader;
