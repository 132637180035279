import { combineReducers } from "redux";
import chatReducer from "./chatReducer";
import homeReducer from "./homeReducer";
import errorReducer from "./errorReducer";
import userReducer from "./userReducer";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import faqReducer from "./faqReducer";
import blogReducer from "./blogReducer";

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // if you do not want to persist this part of the state
  blacklist: ['error', 'home'],
};

const rootReducer = combineReducers({
  chat: chatReducer,
  user: userReducer,
  home: homeReducer,
  faqs: faqReducer,
  error: errorReducer,
  blog: blogReducer
  //other reducers come here...
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer
