export const SET_USER = "SET_USER";
export const LOG_OUT = "LOG_OUT";
export const SET_NOTIF = "SET_NOTIFICATIONS";
export const SHOW_USER_MENU = "SHOW_USER_MENU";
export const DOWNLOAD_APP = 'DOWNLOAD_APP'

const defaultState = {
  user: {},
  loggedIn: false,
  notifications: [],
  userMenuVisible: false,
  registeredUsers: 0
};
const currentUser = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        loggedIn: true,
      };
    case LOG_OUT:
      return {
        ...state,
        user: {},
        loggedIn: false,
        notifications: [],
      };
    case SET_NOTIF:
      return {
        ...state,
        notifications: action.payload,
      };
    case SHOW_USER_MENU:
      return {
        ...state,
        userMenuVisible: action.payload,
      };
    case DOWNLOAD_APP:
      return {
        ...state,
        registeredUsers: action.payload
      }
    default:
      return state;
  }
};

export default currentUser;
