import { isMobile } from "react-device-detect";
export const usernameRegex = /^[A-Za-z0-9-$&+,:;=?@#|'"<>.^*()%!_]{3,20}$/;
export const passwordRegex = /^[A-Za-zÀ-ȕ0-9 $&+,:;=?@#|'"<>.^*()%!-]{6,20}$/;
//eslint-disable-next-line
export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
export const MIN_AGE_ALLOWED = 14;
export const titleForCommunityRgx =
  /^[A-Za-zА-Яа-яñńÄäÜüÒŌÓØÖÕöōòøõóúūùûüÿàáāãåâæçćčéèêēëėęïîìíīįôœÙÛÚÜŪŸÀÁĀÅÃÂÆÇÉÈÊËĒĘĖÏĪÍÌĮÎÔŒÑŃĆČ ][A-Za-zА-Яа-яñńÄäÜüÒŌÓØÖÕöōòøõóúūùûüÿàáāãåâæçćčéèêēëėęïîìíīįôœÙÛÚÜŪŸÀÁĀÅÃÂÆÇÉÈÊËĒĘĖÏĪÍÌĮÎÔŒÑŃĆČ $&+,:;=?@#|'"<>.^*()%!-]{0,50}$/;
export const descriptionForCommunity =
  /^[A-Za-zА-Яа-яñńÄäÜüÒŌÓØÖÕöōòøõóúūùûüÿàáāãåâæçćčéèêēëėęïîìíīįôœÙÛÚÜŪŸÀÁĀÅÃÂÆÇÉÈÊËĒĘĖÏĪÍÌĮÎÔŒÑŃĆČ ][A-Za-zА-Яа-яñńÄäÜüÒŌÓØÖÕöōòøõóúūùûüÿàáāãåâæçćčéèêēëėęïîìíīįôœÙÛÚÜŪŸÀÁĀÅÃÂÆÇÉÈÊËĒĘĖÏĪÍÌĮÎÔŒÑŃĆČ $&+,:;=?@#|'"<>.^*()%!-]*$/;

export const splitWords = (word = "", symbol = " ", splitPosition = 0) => {
  let arr = word.split(symbol);
  return {
    first: arr.slice(0, splitPosition).join(" ") + symbol,
    second: arr.slice(splitPosition).join(" "),
  };
};

export const highlightMatch = (text, match) => {
  if (match && match.length > 0) {
    const regex = new RegExp(match, "gi");
    const newText = text.replace(regex, `<span>$&</span>`);
    return <p dangerouslySetInnerHTML={{ __html: newText }} />;
  } else return text;
};

export function occurrences(string, substring) {
  var a = [],
    i = -1;
  while ((i = string.indexOf(substring, i + 1)) >= 0) a.push(i);
  return a;
}

export function findMatches(sections, word) {
  return sections.reduce((result, sector) => {
    let matches = Object.values(sector)
      .map((val) => {
        let res = Array.isArray(val)
          ? val.map((v) => Object.values(v).join(" ")).join(" ")
          : val;
        return {
          [sector.key]: occurrences(res.toLowerCase(), word.toLowerCase()),
        };
      })
      .filter((ma) => ma[sector.key].length > 0);
    return [
      ...result,
      ...(matches.length > 0
        ? matches[0][sector.key].map((m) => ({ [sector.key]: m }))
        : []),
    ];
  }, []);
}

export const className = (name) =>
  `${name} ${name}-${isMobile ? "mobile" : "desktop"}`;


export const scrollToSection = (id, offsetTop = 0) => {
  let anchorTarget = document.getElementById(id);
  if (anchorTarget) {
    anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
    document.getElementById("scrollContainer").scrollTo({
      left: 0,
      top: anchorTarget.offsetTop - offsetTop,
      behavior: "smooth",
    });
  }
};

export const IsWindow = typeof window != "undefined" ? window : undefined;

export const webTranslationsParser = (data, result) => {
  let { key, web_translations } = data;
  Object.keys(web_translations).map((lang) => {
    return (result[lang] = {
      ...(result[lang] ? result[lang] : {}),
      [key]: web_translations[lang],
    });
  });
  return result;
};
export const ageOver = (birthDate, minAge) => {
  if (!birthDate) {
    return false;
  }
  let moment = require("moment");
  let today = new Date();
  if (moment.duration(today - birthDate).years() >= minAge) {
    return true;
  } else {
    return false;
  }
};

export const getUnseen = (notifications = []) => {
  let unSeen = notifications.filter(
    (item) => item.answersCount !== item.answers_viewed
  );
  return unSeen;
};
export const errorUtill = (err) => {
  let result;
  let translations = {
    "auth/email-already-in-use": "t_error_register_mail",
    "auth/invalid-email": "t_invalid_email_address",
    "auth/internal-error": "t_wrong_pass_or_email",
    "auth/user-not-found": "t_errorcode_no_such_user",
    "auth/wrong-password": "t_wrong_pass_or_email",
    "auth/weak-password": "Cлаба парола.",
    "auth/too-many-requests": "t_error_try_late",
  };
  switch (err) {
    case "auth/email-already-in-use":
    case "auth/invalid-email":
    case "auth/internal-error":
    case "auth/user-not-found":
      result = { for: "email", error: translations[err] };
      break;
    case "auth/wrong-password":
    case "auth/weak-password":
      result = { for: "password", error: translations[err] };
      break;
    case "t_required_field":
      result = { for: "required_field", error: err };
      break;
    case "t_must_be_at_least_14":
      result = { for: "datePicker", error: err };
      break;
    case "t_choose_username ":
      result = { for: "username", error: err };
      break;
    default:
      result = { for: "unknown", error: translations[err] || err };
      break;
  }
  return result;
};

export const replaceNbsps = (str) => {
  var re = new RegExp(String.fromCharCode(160), "g");
  return str.replace(re, " ");
};

export const defautAvatar = (gender) => {
  let avatars = {
    male: "https://firebasestorage.googleapis.com/v0/b/feia-v2-stage.appspot.com/o/avatars%2Fm2.png?alt=media&token=5393f78c-766c-4df9-9705-310a3e028914",
    female:
      "https://firebasestorage.googleapis.com/v0/b/feia-v2-stage.appspot.com/o/avatars%2Ff4.png?alt=media&token=347c9cb2-192d-4250-8da9-64760c33c85c",
  };
  return avatars[gender];
};

export const removEmptyParagraph = (text) => {
  return text.replace(/<p><br>\s*<\/p>/g, '');
}