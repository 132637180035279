import { className } from '../../Utils'

import { faceBook2Icon, twitterIcon, emailIcon, } from '../../Images'

import './SocialMediaIcons.css'

const SocialMediaIcons = ({ url, title }) => {
    const data = [
        {
            imgSrc: faceBook2Icon,
            url: 'https://facebook.com/sharer/sharer.php?u='
        },
        {
            imgSrc: twitterIcon,
            url: 'https://twitter.com/intent/tweet?url='
        },
        {
            imgSrc: emailIcon,
            url: (header) => `mailto:?subject=${header}&body=`,
            mail: true
        }
    ]
    return (
        <>
            {data.map((item, index) => (
                <a key={index}
                    href={item.mail
                        ? `${item.url(title)}${url}`
                        : `${item.url}${url} 
                        `}
                    target="_blank"
                    rel="noreferrer"
                    title={title}
                >
                    <img
                        src={item.imgSrc}
                        className={className('social-media-icons')}
                        alt={title}
                    />
                </a>
            ))}
        </>
    )
}

export default SocialMediaIcons
