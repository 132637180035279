import React from 'react'
import CountryPicker from '../CountryPicker/CountryPicker'
import { className } from '../../../../Utils'
import './MenuMobileFooter.css'

const footerItems = [
    {
        id: 1,
        href: "https://www.facebook.com/FeiaBulgaria",
        iconClassName: 'menu-fb'
    },
    {
        id: 2,
        href: "https://www.instagram.com/feia_bulgaria",
        iconClassName: 'menu-insta'
    },
    {
        id: 3,
        href: "tel:0877833111",
        iconClassName: 'menu-phone'
    },
    {
        id: 4,
        href: "mailto:office@feia.bg",
        iconClassName: 'menu-mail'
    }
]

const MenuMobileFooter = () => {
    return (
        <div className={"menuFooter"}>
            <div className={className('menu-footer-container')}>
                {footerItems.map((item) => (
                    <a key={item.id}
                        href={item.href}
                        className="social-icon-Mobile"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className={item.iconClassName} />
                    </a>
                ))}
            </div>
            <div className='country-picker-mobile-container'>
                <CountryPicker />

            </div>

        </div>
    )
}

export default MenuMobileFooter