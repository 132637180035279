import React, { useRef, useEffect } from "react";
import "./TermsPopup.css";
import { isMobile } from "react-device-detect";
import { useTranslations } from "../../../../lang/TranslationHooks";

export default function TermsPopup({ toggleModal }) {
  const termsRef = useRef(null);
  const { staticTranslate, translateHtml } = useTranslations()

  useEffect(() => {
    if (termsRef.current) {
      termsRef.current.innerHTML = translateHtml("t_terms_and_conditions");
    }
  }, [termsRef, translateHtml]);

  return (
    <div className={`userMenu${isMobile ? " -mobile" : ""}`}>
      <div className="controlBar">
        <div className="closeIcon" onClick={toggleModal} />
        <div className="gap" />
      </div>
      <div className="termsContainer">
        <span className="termsTitle">{staticTranslate("t_terms", true)}</span>
        <span className="termsBody" ref={termsRef} />
      </div>
    </div>
  );
}
