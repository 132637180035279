import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeModal } from "../../store/actions/chatActions";

import ModalDialog from "../CommunityPage/components/Modal/Modal";
import TermsPopup from "../CommunityPage/components/TermsPopup/TermsPopup";
import NewPostPopup from "../CommunityPage/components/NewPostPopup/NewPostPopup";
import RulesPopup from "../CommunityPage/components/rulesPopup/RulesPopup";


export const Popups = () => {
  let dispatch = useDispatch();
  const { chat } = useSelector((state) => state);

  let componentsMap = {
    editThreadPopup: (
      <NewPostPopup
        type={"eddit"}
        categories={chat.categories}
        selectedCategory={chat.selectedCategory}
        selectedTred={chat.selectedTred}
        toggleModal={() => {
          dispatch(removeModal("editThreadPopup"));
        }}
      />
    ),
    threadPopup: (
      <NewPostPopup
        type={"add"}
        categories={chat.categories}
        selectedCategory={chat.selectedCategory}
        selectedTred={chat.selectedTred}
        toggleModal={() => {
          dispatch(removeModal("threadPopup"));
        }}
      />
    ),
    rulesPopup: (
      <RulesPopup
        toggleModal={() => {
          dispatch(removeModal("rulesPopup"));
        }}
      />
    ),
    termsPopup: (
      <TermsPopup
        toggleModal={() => {
          dispatch(removeModal("termsPopup"));
        }}
      />
    ),
  };

  if (chat?.modalList?.length > 0) {
    return chat.modalList.map((modal, index) => (
      <ModalDialog
        key={index}
        isOpen={true}
        toggleModal={() => {
          dispatch(removeModal(modal));
        }}
        content={componentsMap[modal]}
      />
    ));
  }
};
