import { isMobile } from "react-device-detect";
import { className } from "../../../Utils";

import StoreSection from "../../storeSection/StoreSection.js";
import PregnancyCounter from "./PregnancyCounter.js";
import InstallNow from "../../InstallNow/InstallNow";
import { useTranslations } from "../../../lang/TranslationHooks";

import blackArrow from '../assets/black-arrow.png'
import './PageHeader.css'
import { useSelector } from "react-redux";

const PageHeader = ({ sections }) => {
    let { staticTranslate } = useTranslations();
    const { language } = useSelector((state) => state.home)
    return (
        <>
            <div className={className('pregnancy-header-container')} id={sections}>
                <div className={className(`pregnancy-header-image-container-${language}`) + ' ' + className('pregnancy-header-image-conainer')}>
                    <div className={className('pregnancy-wrapper')}>
                        <div className={className('pregnancy-info-container')}>
                            <h1 data-text={staticTranslate('t_pregnancy_header_title', true)}
                                className={className('pregnancy-header-title-color') + " " + className('new-header')}>
                                {staticTranslate('t_pregnancy_header_title', true)}
                            </h1>
                            <p className={className('pregnancy-subtitle')}>
                                {staticTranslate('t_pregnancy_header_subtitle', true)}
                            </p>
                        </div>
                        {isMobile && <div className={className('pregnancy-header-install-now-arrow')}>

                            <div className={className('pregnancy-header-arrow-container')}>
                                <img
                                    src={blackArrow} alt="arrow"
                                    className={className('pregnancy-header-arrow')}
                                    loading="lazy"
                                />
                            </div>
                            <InstallNow
                                text={staticTranslate('t_pregnancy_install_free', true)}
                                intClasName={language !== 'bg' && 'international-install-for-free'}
                            />

                            <div className={className('pregnancy-header-store-container')}>
                                <StoreSection />
                            </div>

                        </div>}
                        {!isMobile && (
                            <div className={className('pregnancy-header-store-container')}>
                                <StoreSection />
                            </div>
                        )}


                        {!isMobile && <>
                            <div className={className('pregnancy-header-install-now-arrow')}>
                                <InstallNow
                                    text={staticTranslate('t_pregnancy_install_free', true)}
                                    loading='lazy'
                                />
                                <div className={className('pregnancy-header-arrow-container')}>
                                    <img
                                        src={blackArrow} alt="arrow"
                                        className={className('pregnancy-header-arrow') + ' ' + className(language !== 'bg' && 'pregnancy-header-arrow-global')}
                                    />
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div >
            <div>
                <PregnancyCounter
                    title={staticTranslate('t_pregnancy_counter_title', true)}
                />
            </div>
        </>

    )
}

export default PageHeader