import { isMobile } from "react-device-detect";

import { className } from "../../../Utils";
import { useTranslations } from "../../../lang/TranslationHooks";

import communityImage from '../assets/webp/laptop3.webp'
import "./Community.css";

const Community = () => {
    let { staticTranslate } = useTranslations();

    return (
        <div className={className("home-section")} id={'section02'}
        >
            <div className={className('home-section-image-container')}>
                <img src={communityImage} className={className('community-image')} alt="Feia Community" loading="lazy" />
            </div>

            <div className={className('home-section-content-container')}>
                <h2 className={className('home-section-title')}>
                    {staticTranslate('t_home_feia', true)} <span> {staticTranslate('t_home_community_title', true)} </span>
                </h2>
                <h3 className={className('home-section-subtitle')}>
                    {staticTranslate('t_home_community_subtitle', true)}
                </h3>

                <div className={className('home-section-paragraph')}
                    dangerouslySetInnerHTML={{
                        __html: staticTranslate('t_home_community_description')
                    }}
                />
                <div
                    className={className('home-section-paragraph') + ' ' + className('community-last-container')}
                    dangerouslySetInnerHTML={{
                        __html: isMobile
                            ? staticTranslate('t_home_community_secondary_description_mobile')
                            : staticTranslate('t_home_community_secondary_description', true)
                    }} />
                <a
                    href="https://feia.bg/community/"
                    target="_blank"
                    rel="noreferrer"
                    title={staticTranslate('t_home_community_button_name', true)}
                >
                    <p className={className('home-section-button')}>
                        {staticTranslate('t_home_community_button_name', true)}
                    </p>
                </a>
            </div>
        </div>
    );
}

export default Community