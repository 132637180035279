import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTranslations } from "../../../../lang/TranslationHooks";

import { isMobile } from "react-device-detect";
import { createThread, edditThread } from "../../../../store/api/community";

import DropDownPicker from "../DropDown/DropDownPicker";
import FeiaInput from "../FeiaInput/FeiaInput";

import { addModal } from "../../../../store/actions/chatActions";

import edditButtonWhite from "../../assets/editIcon.svg";

import "./NewPostPopup.css";

export default function NewPostPopup({
  toggleModal,
  categories,
  selectedCategory,
  selectedTred,
  type,
}) {
  const userState = useSelector((state) => state.user);
  const { language } = useSelector((state) => state.home)

  const dispatch = useDispatch();
  const { staticTranslate } = useTranslations()

  let [isValidTitle, checkTitle] = useState(true);
  let [isValidDescription, checkDescription] = useState(true);
  let [selectedCat, setSelectedCat] = useState(selectedCategory);
  let [threadTitle, setThreadTitle] = useState(
    type === "eddit" ? selectedTred.title : ""
  );
  let [threadDescription, setThreadDescription] = useState(
    type === "eddit" ? selectedTred.description : ""
  );

  const isValid = () => {
    if (
      isValidTitle &&
      threadTitle.length > 0 &&
      isValidDescription &&
      threadDescription.length > 0 &&
      selectedCat != null
    ) {
      return true;
    }

    return false;
  };

  const send = async () => {
    //send
    if (type === "eddit") {
      edditThread(
        selectedTred.id,
        threadTitle,
        threadDescription,
        selectedCat.id,
        userState.user.uid,
        language
      );
    } else {
      createThread(
        threadTitle,
        threadDescription,
        selectedCat.id,
        userState.user.uid,
        language
      );
    }

    //close
    toggleModal();
  };

  return (
    <div className={`newPostContent${(isMobile && "Mobile") || ""}`}>
      <div className="modalTopBar">
        <img
          src={edditButtonWhite}
          className={"addThemeIcon"}
          alt="addThemeIcon"
        />
        <div className="modalTitle">
          {type === "add"
            ? staticTranslate("t_create_theme", true)
            : staticTranslate("t_edit_theme", true)}
        </div>
        <div
          className="infoIcon"
          onClick={() => dispatch(addModal("rulesPopup"))}
        />
        <div className="close" onClick={toggleModal} />
      </div>
      <div className="modalBody">
        <div className="gap" />
        <div className="feiaImage" />
        <DropDownPicker
          options={categories.map((ctg) => ctg.title)}
          placeholder={"Избери категория"}
          defaultOption={(selectedCat && selectedCat.title) || null}
          onSelect={(selected) =>
            setSelectedCat(
              categories.find((ctg) => ctg.title === selected.label)
            )
          }
        />
        <div className="gap" style={{ height: "5%" }} />

        <FeiaInput
          defaultValue={threadTitle}
          prompt={`${staticTranslate("t_title", true)}...`}
          limit={50}
          maxRows={2}
          inputHandler={(val, isCyrilic) => {
            setThreadTitle(val);
            checkTitle(isCyrilic);
          }}
          cyrillicErrorMsg={staticTranslate("t_use_cyrillic", true)}
        />
        <div style={{ height: "5%" }} />

        <FeiaInput
          defaultValue={threadDescription}
          prompt={`${staticTranslate("t_description", true)}...`}
          limit={500}
          maxRows={4}
          inputHandler={(val, isCyrilic) => {
            setThreadDescription(val);
            checkDescription(isCyrilic);
          }}
          cyrillicErrorMsg={staticTranslate("t_use_cyrillic", true)}
        />

        <div style={{ height: "5%" }} />
        <div
          className={`modalButton${isValid() ? "" : " disabled"}`}
          onClick={(e) => (isValid() && send()) || {}}
        >
          {type === "add"
            ? staticTranslate("t_post", true)
            : staticTranslate("t_save", true)}
        </div>
        <div className="gap" style={{ height: "5%" }} />
      </div>
    </div>
  );
}
