import React from 'react'
import { useTranslations } from "../../../lang/TranslationHooks";

import { clouds } from '../../../Images'
import { className } from '../../../Utils'
import { aboutUs_artwork } from '../../../Images'

import './AboutUsHeader.css'
const AboutUsHeader = () => {

    let { staticTranslate } = useTranslations();

    return (
        <div className={className('aboutUs-header')} id="section00">
            <div className={className('aboutUs-artwork')}>
                <img src={aboutUs_artwork} alt="artwork" style={{ marginLeft: '25vw' }}  loading='lazy'/>
            </div>
            <h1
                data-text={staticTranslate('t_about_us_header_title', true)}
                className={className('aboutUs-header-title')}

            >{staticTranslate('t_about_us_header_title', true)}</h1>

            <div className={className('aboutUs-header-image-container')}>
                <img src={clouds} alt="cloud" loading='lazy' />
            </div>
        </div>
    )
}

export default AboutUsHeader