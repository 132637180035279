import React from 'react'
import MenuMobileFooter from './MenuMobileFooter';
import { HashLink } from 'react-router-hash-link';

const MenuMobileDropDown = ({ packageJson, container, appsExpand, sections, onMenuClicked }) => {
    return (
        <div className={"menuDropDown"} ref={container}>
            <div className={"menuItems"}>
                <div className={appsExpand ? "appsList" : "appsList closed"}>
                    {sections.map((page) => {
                        return (
                            <div key={page.key} >
                                <HashLink to={'/'}
                                    elementId={page.key}
                                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: "center" })}
                                    title={page.title}
                                    onClick={onMenuClicked}
                                >
                                    {page.title}
                                </HashLink >
                            </div>

                        );
                    })
                    }

                </div>

            </div>
            <div className={"mobile-version"}>{packageJson.version}</div>
            <MenuMobileFooter />
        </div>
    )
}

export default MenuMobileDropDown