import React from 'react'
import { className } from '../../../Utils'

import './AboutUsSingleContent.css'

const AboutUsSingleContent = ({ title, text, imgPath, id, img2 }) => {
    return (
        <div className={className('aboutUs-single-content')}>
            <div className={className('aboutUs-single-content-context')}>
                <p className={className('aboutUs-single-content-title') + ' ' + className(`aboutUs-single-content-title-${id}`)}>
                    {title}
                </p>
                <p className={className('aboutUs-single-content-info')}
                    dangerouslySetInnerHTML={{
                        __html: text
                    }} />

            </div>
            <div className={className('single-content-image-container')}>
                <img src={imgPath} alt={title}  loading='lazy'/>
            </div>
        </div>
    )
}

export default AboutUsSingleContent