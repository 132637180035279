import React from 'react'
import { facebookIcon, instagramIcon } from "../../../../Images";
import './SocialMediaContainer.css'
import { useSelector } from 'react-redux';

const SocialMediaContainer = () => {
    const { language } = useSelector((state) => state.home)

    const itemsData = [
        {
            id: 1,
            href: language === 'bg' ? "https://www.facebook.com/FeiaBulgaria" : "https://www.facebook.com/gaiapregnancyapp/",
            icon: facebookIcon,
            iconClassName: 'facebookIcon',
            alt: 'Facebook Icon'
        },
        {
            id: 2,
            href: language === 'bg' ? "https://www.instagram.com/feia_bulgaria" : "https://www.instagram.com/gaia_pregnancy_app",
            icon: instagramIcon,
            iconClassName: 'instagramIcon',
            alt: "Instagram logo"
        }
    ]
    return (
        <div className={"social-media-links"}>
            {itemsData.map((item) => (
                <a key={item.id} href={item.href} className="social-icon" target="_blank" rel="noreferrer" title={item.alt}>
                    <div className='social-media-container'>
                        <img src={item.icon} className={item.iconClassName} alt={item.alt} loading='lazy' />
                    </div>
                </a>
            ))}

        </div>
    )
}

export default SocialMediaContainer