import axios from "axios";
import { FETCH_BLOGS_FAILED, GET_ALL_BLOGS } from "../reducers/blogReducer";


export function getLastBlogs() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`https://feia.bg/shop/blog-site/wp-json/wp/v2/posts?per_page=3`);
            dispatch({ type: GET_ALL_BLOGS, payload: response.data })

        } catch (err) {
            dispatch({ type: FETCH_BLOGS_FAILED, payload: err.message })
        }
    }
}