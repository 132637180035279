import React from 'react'
import AboutUsSingleContent from './AboutUsSingleContent'
import { useTranslations } from "../../../lang/TranslationHooks";
import { useSelector } from 'react-redux';

import { className } from '../../../Utils'

import aboutUsBlob1 from '../assets/newImg/aboutUs1.webp'
import aboutUsBlob2 from '../assets/newImg/aboutUs2.webp'
import aboutUsBlob3 from '../assets/newImg/aboutUs3.webp'
import aboutUsBlog1En from '../assets/newImg/aboutUs-en.webp'

import './AboutUsContent.css'

const AboutUsContent = () => {
    let { staticTranslate } = useTranslations();
    const { language } = useSelector((state) => state.home)

    const content = [
        {
            id: 1,
            title: staticTranslate('t_about_us_content_1_title', true),
            text: staticTranslate('t_about_us_content_1_description'),
            imgPath: language === 'bg' ? aboutUsBlob1 : aboutUsBlog1En,
        },
        language === 'bg' &&
        {
            id: 2,
            title: staticTranslate('t_about_us_content_2_title', true),
            text: staticTranslate('t_about_us_content_2_description'),
            imgPath: aboutUsBlob2,

        },
        {
            id: 3,
            title: staticTranslate('t_about_us_content_3_title', true),
            text: staticTranslate('t_about_us_content_3_description'),
            imgPath: aboutUsBlob3,
        }
    ].filter(Boolean)

    return (
        <div className={className('aboutUs-content-wrapper')}>
            {content.map((item) => (
                <AboutUsSingleContent key={item.id} {...item} />
            ))}
        </div>
    )
}

export default AboutUsContent