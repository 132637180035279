import { useSelector } from "react-redux";

export function useTranslations() {
  let { translations, language } = useSelector((state) => state.home);

  const staticTranslations = translations[language];

  const staticTranslate = (translationString, withRegex) => {
    translationString = translationString?.toLowerCase()
    let result = staticTranslations
      ? staticTranslations[translationString]
      : translationString;

    if (result === undefined) {
      return translationString
    }

    if (withRegex) {
      const regex = /<\/?p[^>]*>/g;
      const newLineRegex = /\\\\n/g;
      result = result.replace(regex, " ");

      //Add new lines if any
      if (newLineRegex.test(result)) {
        result = result.split(newLineRegex).join("\n");
      }
    }

    return result;
  };

  const translateHtml = (translateText) => {
    let result = staticTranslations[translateText]
      ? staticTranslations[translateText]
      : translateText;
    if (result.indexOf("<p>") > -1) {
      return result;
    } else {
      return `<p>${result}</p>`;
    }
  };

  return { staticTranslate, translateHtml };
}