import { className } from '../../../../Utils'

import moviePlayerButton from "../../assets/newPlayButton.svg";
import { useTranslations } from '../../../../lang/TranslationHooks';

import './AccordionHeader.css'

const AccordionHeader = ({ title, SetIsOpenVideoHandler }) => {
    let { staticTranslate } = useTranslations();

    return (
        <header className={className('container-header')}>
            <div className={className("contaier-header-image-container")}>
                <button
                    className={className('btn')}
                    onClick={() => SetIsOpenVideoHandler(prevState => !prevState)}
                >
                    <img
                        src={moviePlayerButton}
                        className={className('movie-player')}
                        alt="btn"
                        loading='lazy'
                    />
                </button>
                <p className={className('container-header-title')}>
                    {title}
                </p>
            </div>
            <div className={className('container-header-text-container')}>
                <p> {staticTranslate('t_pregnancy_free', true)}</p>

            </div>
            <div className={className('container-header-last-text')}>
                <p>{staticTranslate('t_pregnancy_premium', true)}</p>
            </div>
        </header>
    )
}

export default AccordionHeader
