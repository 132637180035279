import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import VideoRendering from '../common/VideoRendering/VideoRendering';

import { className } from '../../Utils';

import Arrows from './Arrows'
import PhoneFramePregnancy from '../Pregnancy/assets/PhoneFrame2.png'

import './Slider.css'

const Slider = ({ sliderData }) => {
    const { language } = useSelector((state) => state.home)
    const [activeIndex, setActiveIndex] = useState(0);
    const [isPlay, setIsPlay] = useState(true);
    let len = sliderData?.length - 1

    const vidRef = useRef(null);
    const handlePlayVideo = () => {
        if (isPlay) {
            vidRef.current.pause();
        } else {
            vidRef.current.play();
        }
        setIsPlay(!isPlay)
    }

    return (
        <div className={className('slider-wrapper')}>
            <div className={className('image-container')}>
            </div>
            <div className={className('slider-container')} >
                <Arrows
                    prevSlide={() => setActiveIndex(activeIndex < 1
                        ? len : activeIndex - 1)}
                    nextSlide={() => setActiveIndex(activeIndex === len
                        ? 0 : activeIndex + 1)}
                />
                <div className={className('section')}>
                    <img
                        src={PhoneFramePregnancy}
                        className={className('section-phone-img')}
                        alt="phone"
                    />
                    {sliderData?.map((slide) => {
                        const lastIndex = slide.url.lastIndexOf('.');
                        const type = slide.url.substr(lastIndex, slide.url.lenght)

                        return <div
                            key={slide.id}
                            className={className(slide.id - 1 === activeIndex
                                ? 'slides' : 'inactive ') + ` active`
                            }
                        >
                            {type === '.mp4'
                                ? <div className={className('video-wrapper')}>
                                    <button className={className('btn-player') + ' ' + className(!isPlay ? "play-btn" : 'pause-btn')}
                                        onClick={() => handlePlayVideo()}> </button>
                                    <VideoRendering
                                        videoUrl={slide.url}
                                        cssStyles="slider-video"
                                        vidRef={vidRef}
                                    />
                                   
                                </div>
                                : <img
                                    src={slide.url}
                                    className={className('slider-img') + ' ' + className(language !== 'bg' && 'slider-img-international')}
                                    alt='img'
                                />
                            }
                        </div>
                    })}
                </div>
            </div>
            <div className={className('image-container')}>
            </div>
        </div>
    )
}

export default Slider
