import React from 'react'
import { useTranslations } from "../../../lang/TranslationHooks";

import { className } from '../../../Utils'
import { clouds, artwork } from '../../../Images'

import './IOSPaymentTitle.css'

const IOSPaymentTitle = () => {
    let { staticTranslate } = useTranslations();

    return (<>
        <div className={className('ios-payment-title-wrapper')} id="section00">
            <div className={className('ios-payment-artwork-container')}>
                <img src={artwork} alt="artwork" loading='lazy' />
            </div>
            <div className={className('ios-payment-cloud-container')}>
                <img src={clouds} alt="cloud" loading='lazy' />
            </div>
            <div >
                <h1 className={className('ios-payment-title')}>{staticTranslate('t_ios_header_title', true)}</h1>
            </div>
        </div>
    </>

    )

}

export default IOSPaymentTitle