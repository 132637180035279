import { className } from '../../../../Utils';

import checked from "../../assets/checked.svg";
import newIcon from '../../assets/newXIcon.svg'
import arrowLeft from '../../assets/arrow-left.svg'
import arrowLeftUp from '../../assets/arrow-left-up.svg'
import whiteChecked from '../../assets/white-checked.svg'
import whiteXIcon from '../../assets/whiteXICcon.svg'
import './AccordionContentMobile.css'

const AccordionContentMobile = ({ id, freeFunctionality, itemTitle, contentMobile, toggle, clicked }) => {

    return (
        <article
            className={`accordion-container-mobile  ${clicked === id ? 'showInfo' : ''}`}
        >
            <div className={className('accordion-wrapper')}>
                <div className='accordion-wrapper-left-container'>
                    <button className={className('btn')}
                        onClick={() => toggle(id)}
                    >
                        <h5>{itemTitle}</h5>
                    </button>
                    <img
                        onClick={() => toggle(id)}
                        src={clicked !== id
                            ? arrowLeft : arrowLeftUp}
                        className={clicked === id
                            ? 'accordion-icon-arrow-open-mobile'
                            : 'accordion-icon-mobile-arrow'}
                        alt="arrow"
                        loading='lazy'
                    />
                </div>
                <div className='accordion-wrapper-middleIcon'>
                    {freeFunctionality
                        ? <img
                            src={clicked === id ? whiteChecked : checked}
                            className="accordion-icon-mobile-cheked" alt="checked"
                            loading='lazy'
                        />
                        : <img
                            src={clicked === id ? whiteXIcon : newIcon}
                            className="accordion-icon-freeIcon-mobile"
                            alt="free icon"
                            loading='lazy'
                        />
                    }

                </div>
                <div className="accordion-wrapper-crown">
                    <img
                        src={clicked === id ? whiteChecked : checked}
                        className="accordion-icon-mobile-cheked" alt="checked"
                        loading='lazy'
                    />
                </div>
            </div>
            <div>
                {clicked === id
                    ? <p
                        dangerouslySetInnerHTML={{ __html: contentMobile }}
                        className='accordion-text-info-mobile'
                    ></p>
                    : null}
            </div>
        </article>
    )
}

export default AccordionContentMobile
