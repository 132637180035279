import { useEffect, useState } from "react";

import { Modal } from "react-responsive-modal";

import { className } from '../../../../Utils'

import "react-responsive-modal/styles.css";
import './AccordionVideo.css'
import phoneFrame from '../../assets/PhoneFrame.png'

const AccordionVideo = ({ videoUrl, isOpenVideo, SetIsOpenVideoHandler, title }) => {
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (isOpenVideo) {
            setIsLoading(true)
        }
        const timeoutId = setTimeout(() => {
            setIsLoading(false)
        }, 2000);

        // Clean up the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);
    }, [isOpenVideo]);

    if (isOpenVideo) {
        return (
            <div className='video'>
                <Modal
                    open={isOpenVideo}
                    onClose={() => {
                        SetIsOpenVideoHandler(!isOpenVideo)
                        setIsLoading(true)
                    }}
                    center
                >
                    {isLoading
                        ? <div className={className('new-loader')} />


                        : <div className={className("modal-phone-container")}>
                            <img src={phoneFrame} alt="plhone" />
                            <video
                                className={className('pregnancy-modal-video')}
                                src={videoUrl}
                                title={title}
                                playsInline={true}
                                webkit-playsinline="true"
                                autoPlay="autoplay"
                                loop
                                muted
                            />
                        </div>
                    }
                </Modal>
            </div>
        )
    }
}

export default AccordionVideo
