import { isMobile } from "react-device-detect";
import { useTranslations } from "../../lang/TranslationHooks";
import { useSelector } from "react-redux";
import { className } from "../../Utils";

import Menu from "../common/Menu/Menu";
import Welcome from "./welcome/Welcome";
import Shop from "./shop/Shop";
import Blog from "./blog/Blog";
import Community from "./community/Community";
import Footer from "../common/footer/Footer";

import flowersR from "../Home/assets/webp/flowersR.webp";
import flowersL from "../Home/assets/webp/flowersL.webp";

import "./Home.css";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import { Popups } from "../Popups/Popups";
import RatingWrapper from "./RatingWrapper/RatingWrapper";

const Home = () => {
  let { staticTranslate } = useTranslations();
  const { translations, language } = useSelector(state => state.home)

  const ratingData = [
    {
      id: 1,
      text: staticTranslate('t_home_rating_first_text'),
      textMobile: staticTranslate('t_home_rating_first_textmobile'),
      author: staticTranslate('t_home_rating_first_author', true)
    },
    {
      id: 2,
      text: staticTranslate('t_home_rating_second_text'),
      textMobile: staticTranslate('t_home_rating_second_textmobile'),
      author: staticTranslate('t_home_rating_second_author', true),
    },
    {
      id: 3,
      text: staticTranslate('t_home_rating_third_text'),
      textMobile: staticTranslate('t_home_rating_third_textmobile'),
      author: staticTranslate('t_home_rating_third_author', true)
    },
    {
      id: 4,
      text: staticTranslate('t_home_rating_fourth_text'),
      textMobile: staticTranslate('t_home_rating_fourth_textmobile'),
      author: staticTranslate('t_home_rating_fourth_author', true),
    },
    {
      id: 5,
      text: staticTranslate('t_home_rating_fift_text'),
      textMobile: staticTranslate('t_home_rating_fift_textmobile'),
      author: staticTranslate('t_home_rating_fift_author', true),
    }
  ]
  return (
    <>

      <GlobalLoader showSpinner={translations[language] ? false : true} />
      <div className="App-home" >
        <div id="main" className={className('main')}>
          <Menu mobile={isMobile} home pageName='home' />
          <div id={"scrollContainer"} className="main-container">
            <img src={flowersR} className={className('right-bar')} alt={'right'} loading="lazy" />
            <img src={flowersL} className={className('left-bar')} alt={'left'} loading="lazy" />
            <Welcome />
            <RatingWrapper ratingData={ratingData} sections="section01" />
            <Community />
            <Blog />
            <Shop />
            <Footer />
            <Popups />
          </div>
        </div>
      </div>
    </>

  );
}

export default Home