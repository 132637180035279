import React from 'react'
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

import Menu from "../common/Menu/Menu";
import LastBlogArticles from '../LastBlogArticles/LastBlogArticles'
import Footer from '../common/footer/Footer';
import GlobalLoader from "../GlobalLoader/GlobalLoader";

import { className } from '../../Utils';

import './Terms.css'
import TersmContent from './TersmContent/TersmContent';
const Terms = () => {
    const { translations, language } = useSelector(state => state.home)

    return (
        <>
            <GlobalLoader showSpinner={translations[language] ? false : true} />
            <div className="App">
                <div id="main" className={className('terms-main')}>
                    <Menu mobile={isMobile} pageName='terms' />
                    <div id={"scrollContainer"} className="terms-main-container">
                        <TersmContent />
                        {language === 'bg' &&
                            <LastBlogArticles sections="section02" />
                        }
                        <Footer />
                    </div>
                </div>
            </div>
        </>

    )
}

export default Terms