import React from 'react'

import MenuMobileDropDown from './MenuMobileDropDown';
import MobileNavbar from './MobileNavbar';

const MobileMenu = ({
    menuIsOpened,
    changeNavbarBg,
    appsExpand,
    showBackToTopButton,
    packageJson,
    home,
    pageName,
    onMenuClicked,
    container,
    currentHeight,
    updateAppsExpand,
    scrollToSection,
    sections,
    activeSection
}) => {
    return (
        <div>
            <MobileNavbar
                changeNavbarBg={changeNavbarBg}
                currentHeight={currentHeight}
                home={home}
                onMenuClicked={onMenuClicked}
                menuIsOpened={menuIsOpened}
                pageName={pageName}
            />
            {menuIsOpened && <MenuMobileDropDown
                container={container}
                packageJson={packageJson}
                appsExpand={appsExpand}
                updateAppsExpand={updateAppsExpand}
                sections={sections}
                pageName={pageName}
                activeSection={activeSection}
                onMenuClicked={onMenuClicked}
            />}

            {showBackToTopButton && <div
                className={"chevronUp"}
                onClick={() => scrollToSection('section00')} />}
        </div>
    )
}

export default MobileMenu