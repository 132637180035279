import { isMobile } from "react-device-detect";

import { className } from '../../Utils';
import { useSelector } from "react-redux";

import Menu from "../common/Menu/Menu";
import ContactsHeader from "./ContactsHeader/ContactsHeader";
import ContactsContent from "./ContacsContent/ContactsContent";
import LastBlogArticles from '../LastBlogArticles/LastBlogArticles'
import Footer from '../common/footer/Footer';
import GlobalLoader from "../GlobalLoader/GlobalLoader";

import "./Contacts.css"

const Contacts = () => {
    const { translations, language } = useSelector(state => state.home)
    return (
        <>
            <GlobalLoader showSpinner={translations[language] ? false : true} />
            <div className="App">
                <div id="main" className={className('contacts-main')}>
                    <Menu mobile={isMobile} pageName='contacts' />
                    <div id={"scrollContainer"} className="contacts-main-container">
                        <ContactsHeader />
                        <ContactsContent />
                        {language === 'bg' &&
                            <LastBlogArticles sections="section04" />
                        }
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contacts