import React, { useState, useEffect } from 'react'
import { SERVER_URL } from '../../../../config/serverUrl'

import { useTranslations } from "../../../../lang/TranslationHooks";

import { className } from '../../../../Utils'
import './ContactsForm.css'

const ContactsForm = () => {
    let { staticTranslate } = useTranslations();
    let [errors, setErrors] = useState({ message: '', name: '', emailAddress: '' });
    const [fields, setFields] = useState({ message: "", name: "", emailAddress: "" });
    const [successMessage, setSuccessMesage] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => setSuccessMesage(false), 3000);
        return () => clearTimeout(timeout);
    }, [successMessage])

    const sendEmail = async (data) => {
        try {
            const result = await fetch(`${SERVER_URL}/admin/send/website-email`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });
            if (result?.status === 200) {
                setSuccessMesage(true)
            }

        } catch (err) {
            console.log(err.message + err.code)
        }
    }

    const validate = (name, value) => {
        switch (name) {
            case "message":
                if (!value) {
                    return "Messega field is Required";
                } else if (value.leng >= 3) {
                    return "Please enter valid message";
                } else {
                    return "";
                }
            case "emailAddress":
                if (!value) {
                    return "Email is Required";
                } else if (
                    !value.match(/^[a-z0-9]([a-z0-9_\-.]*)@([a-z0-9_\-.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i)
                ) {
                    return "Enter a valid email address";
                } else {
                    return "";
                }
            case "name":
                if (!value) {
                    return "Thi field is required";
                } else if (value.length < 3) {
                    return "Please enter your full name";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    }

    const handleUserInput = e => {
        setErrors({
            ...errors,
            [e.target.name]: validate(e.target.name, e.target.value)
        });
        setFields({
            ...fields,
            [e.target.name]: e.target.value
        })
    };

    const handleSubmit = e => {
        e.preventDefault();

        let validationErrors = {};
        Object.keys(fields).forEach(name => {
            const error = validate(name, fields[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            setErrors({ validationErrors })
            return;
        }
        if (fields.message && fields.emailAddress && fields.name) {
            const data = {
                message: fields.message,
                email: fields.emailAddress,
                name: fields.name
            };

            sendEmail(data);
            setFields({ message: "", name: "", emailAddress: "" });
            setErrors({ message: "", name: "", emailAddress: "" })
        }
    };

    return (
        <div className={className('contacts-form')}>
            <p className={className('contacts-form-title')}>{staticTranslate('t_contacts_form_title', true)}</p>

            <form onSubmit={handleSubmit} className={className('contacts-form-container')}>
                <textarea
                    value={fields.message}
                    onChange={(e) => handleUserInput(e)} name="message"
                    type='text'
                    placeholder={staticTranslate('t_contacts_form_my_message', true)}
                    className={className('contacts-form-single-input')}
                    rows={fields.message.length <= 140 ? 8 : 20}
                />
                {<p className={className('contacts-error-message')}>{errors?.validationErrors?.message}</p>}
                <input
                    value={fields.name}
                    onChange={(e) => handleUserInput(e)}
                    type='text'
                    name="name"
                    placeholder={staticTranslate('t_contacts_form_my_name', true) + '...'}
                    className={className('contacts-form-single-input')}
                />
                {<p className={className('contacts-error-message')}>{errors?.validationErrors?.name}</p>}
                <input
                    value={fields.emailAddress}
                    onChange={e => handleUserInput(e)}
                    type='text'
                    name="emailAddress"
                    placeholder={staticTranslate('t_contacts_form_answer_me', true) + '...'}
                    className={className('contacts-form-single-input')}
                />
                {<p className={className('contacts-error-message')}>{errors?.validationErrors?.emailAddress}</p>}
                {successMessage && <p className={className("contacts-message-success")}>{staticTranslate('t_success_message', true)}</p>}
                <button type='submit' className={className('contacs-form-submit')}>
                    {staticTranslate('t_contacts_form_submit', true)}
                </button>
            </form>
        </div>
    )
}

export default ContactsForm
