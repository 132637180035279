import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { clouds, fairyWithQuestions, artwork } from '../../../Images'

import { className } from '../../../Utils'

import './ErrorContent.css'

const ErrorContent = () => {
    const navigate = useNavigate();

    return (
        <div className={className('error-content-wrapper')}>

            <div className={className('error-content-container')}>
                <div className={className('error-artwork')}>
                    <img src={artwork} alt="artwork" />
                </div>
                <p
                    data-text="404"
                    className={className('error-content-wrapper-title')}
                >
                    404
                </p>
                <div className={className('fiary-with-questions-container')}>
                    <img src={fairyWithQuestions} alt="fairy with questions" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div className={className('error-cloud-image-container')}>
                    <img src={clouds} alt="cloud" className='error-cloud-image' />

                </div>
                <div className={className('error-coud-content')}>
                    <p className={className('error-coud-content-title')}><span>ОПА!</span>  СТРАНИЦАТА НЕ Е НАМЕРЕНА!</p>
                    <Link onClick={() => navigate(-1)} className={className('error-cloud-content-go-back')}> Върни се назад </Link>
                    <Link
                        to={'/'}
                    >
                        <p className={className('error-cloud-content-go-home')}>
                            начална страница
                        </p>
                    </Link>
                </div>


            </div>
        </div>
    )
}

export default ErrorContent